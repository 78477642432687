import React from 'react'
import { text } from '../../util/text'
import { ReactComponent as ResizeHandleImage } from './assets/img_resize.svg'
import './assets/Resizable.scss'

const ResizeHandle: React.FC<{}> = () => (
  <ResizeHandleImage title={text.clickAndDragResize} />
)

export default ResizeHandle
