import React from 'react'
import AppHeaderSeparator from './AppHeaderSeparator/AppHeaderSeparator'
import BoardTitle from './BoardTitle/BoardTitle'
import ThemeToggle from '../Theme/ThemeToggle/ThemeToggle'
import OfflineNotification from '../OfflineNotification/OfflineNotification'
import './AppHeader.scss'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../store/RootStateProvider'
import { text } from '../../util/text'
import SavedBoardToggle from './SavedBoardToggle/SavedBoardToggle'
import HeaderButtonRound from './HeaderButtonRound/HeaderButtonRound'
import ReactTooltip from 'react-tooltip'
import { tooltipShowDelay } from '../../util/config'
import { ReactComponent as IconSignUp } from '../Auth/assets/icon_auth.svg'
import { ReactComponent as IconUser } from '../../components/UserThumbnail/assets/icon_user.svg'
import TryanglyLogo from './TryanglyLogo/TryanglyLogo'

interface Props {
  boardId: string | null
}

const AppHeader: React.FC<Props> = observer(({ boardId }) => {
  const authEnabled = process.env.REACT_APP_AUTH_ENABLED === 'true'

  const { boardStore, sessionStore, uiStore } = useRootStore().rootStore
  const user = sessionStore.currentUser

  let boardTitle = ''
  let readOnlyTitle

  if (boardStore.board) {
    boardTitle = boardStore.board.title

    if (boardStore.board.deleted) {
      readOnlyTitle = true
    } else {
      readOnlyTitle = boardStore.board.readOnlyTitle
    }
  }

  const renderAuth = () => {
    let result = null

    if (user && !user.isAnonymous) {
      result = (
        <>
          <HeaderButtonRound
            label={text.yourProfile}
            onPress={() => uiStore.setModalOpen('profile')}
            icon={<IconUser fill="#fff" />}
          />

          <AppHeaderSeparator />
        </>
      )
    } else {
      if (boardStore.board) {
        result = (
          <>
            <HeaderButtonRound
              label={text.signIn}
              onPress={() => uiStore.setAuthModalOpen(true)}
              icon={<IconSignUp fill="#fff" />}
            />
            <AppHeaderSeparator />
          </>
        )
      }
    }

    return result
  }

  return (
    <header className="app-header">
      <div className="app-header__main">
        <Link to="/" title={text.tryangly} className="tryangly-logo">
          <TryanglyLogo />
        </Link>

        {boardTitle ? (
          <>
            <AppHeaderSeparator />

            {authEnabled && <SavedBoardToggle enableTooltip={true} />}

            <BoardTitle
              boardId={boardId}
              title={boardTitle}
              readOnly={readOnlyTitle}
            />
          </>
        ) : null}

        <OfflineNotification />
      </div>

      <div className="app-header__tools">
        {authEnabled && renderAuth()}

        <ThemeToggle />

        <ReactTooltip
          id="header-tooltip"
          place="bottom"
          effect="solid"
          delayShow={tooltipShowDelay}
        />
      </div>
    </header>
  )
})

export default AppHeader
