import React from 'react'
import { Resizable } from 're-resizable'
import AppHeader from '../AppHeader/AppHeader'
import { BoardInterface } from '../../util/interfaces'
import BoardContainer from '../BoardContainer/BoardContainer'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../store/RootStateProvider'
import { getResizableConfig } from './util'
import './assets/Wall.scss'

interface Props {
  visible: boolean
  changeBoard: (board: BoardInterface | null) => void
}

const Wall: React.FC<Props> = observer(({ visible, changeBoard }) => {
  const { boardStore } = useRootStore().rootStore

  let { board } = boardStore
  if (board && board.deleted) {
    board = null
  }

  const resizableConfig = getResizableConfig(
    boardStore.boardId,
    board,
    boardStore.minimumBoardWidth
  )

  return (
    <Resizable
      className={`wall ${visible ? 'wall_visible' : ''}`}
      {...resizableConfig}
    >
      <AppHeader boardId={boardStore.boardId} />

      <BoardContainer boardId={boardStore.boardId} changeBoard={changeBoard} />
    </Resizable>
  )
})

export default Wall
