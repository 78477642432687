import { observable, action, makeObservable } from 'mobx'
import { UserInterface } from '../util/interfaces'

export class UsersStore {
  users: UserInterface[] = []

  setUsers = (users: UserInterface[]) => (this.users = users)

  constructor() {
    makeObservable(this, {
      users: observable,
      setUsers: action,
    })
  }
}
