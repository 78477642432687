import React from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../../store/RootStateProvider'
import './ProfileBoards.scss'
import { Api } from '../../../services/api'
import ReactTooltip from 'react-tooltip'
import SavedBoardListItem from './SavedBoardListItem'

const ListOfBoards: React.FC<{}> = observer(() => {
  const { savedBoardsStore } = useRootStore().rootStore

  const onPressRemoveSavedBoard = (id: string) => {
    ReactTooltip.rebuild()

    const boards = savedBoardsStore.boards
    delete boards[id]

    Api.updateSavedBoards(boards).then(() => {
      savedBoardsStore.setBoards(boards)
    })
  }

  return (
    <ul className="saved-boards">
      {savedBoardsStore.boardsSorted.map((b) => (
        <SavedBoardListItem
          key={b.id}
          id={b.id}
          title={b.title}
          removeSavedBoard={onPressRemoveSavedBoard}
        />
      ))}
    </ul>
  )
})

export default ListOfBoards
