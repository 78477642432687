import React from 'react'
import { observer } from 'mobx-react-lite'
import { Api } from '../../../services/api'
import { useRootStore } from '../../../store/RootStateProvider'
import './ProfileBoards.scss'
import { text } from '../../../util/text'
import SavedBoardToggle from '../../AppHeader/SavedBoardToggle/SavedBoardToggle'
import ListOfBoards from './ListOfBoards'

const ProfileBoards: React.FC<{}> = observer(() => {
  const { boardStore, sessionStore, savedBoardsStore, uiStore } =
    useRootStore().rootStore

  const onPressSaveBoard = () => {
    if (boardStore.boardId && boardStore.board) {
      const b = {
        id: boardStore.boardId,
        title: boardStore.board.title,
        owned: boardStore.board.owner?.id === sessionStore.currentUser.id,
      }

      Api.saveBoard(b).then(() => {
        savedBoardsStore.saveBoard(b)
      })
    }
  }

  const onPressCreateBoard = () => {
    uiStore.closeModal()

    const titleInput = document.getElementById('board-title')
    if (titleInput) {
      titleInput.focus()
    }
  }

  const renderSaveCurrentBoard = () => {
    if (boardStore.board) {
      if (currentBoardIsSaved) {
        return null
      } else {
        return (
          <button type="button" className="link" onClick={onPressSaveBoard}>
            {`Save ${boardStore.board.title}`}
          </button>
        )
      }
    } else {
      return (
        <button type="button" className="link" onClick={onPressCreateBoard}>
          {text.createBoard}
        </button>
      )
    }
  }

  const currentBoardIsSaved = !!(
    boardStore.boardId && savedBoardsStore.boards[boardStore.boardId]
  )

  return (
    <section className="profile">
      <h2 className="hide">Saved boards</h2>

      {savedBoardsStore.boardsSorted.length ? (
        <ListOfBoards />
      ) : (
        <div className="saved-boards__message">
          <SavedBoardToggle location="profile" disabled={true} />

          <p className="text-generic text-centered">
            {text.savedBoardsMessage}
          </p>

          {renderSaveCurrentBoard()}
        </div>
      )}
    </section>
  )
})

export default ProfileBoards
