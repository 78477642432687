import React, { useEffect, useState } from 'react'
import { getInitialTheme } from './utils'

const initialTheme = getInitialTheme()

const ThemeContext = React.createContext({
  theme: initialTheme,
  toggleTheme: () => {},
})

const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const [theme, setTheme] = useState(initialTheme)

  useEffect(() => {
    document.body.classList.toggle('theme-dark', theme === 'dark')
    localStorage.setItem('theme', theme)
  }, [theme])

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
