import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import './HeaderButtonRound.scss'

interface Props {
  label: string
  icon: any
  onPress: any
}

const HeaderButtonRound: React.FC<Props> = observer(
  ({ label, icon, onPress }) => {
    useEffect(() => {
      ReactTooltip.rebuild()
    }, [])

    return (
      <button
        className="header-button-round"
        onClick={onPress}
        type="button"
        aria-label={label}
        data-tip={label}
        data-for="header-tooltip"
      >
        {icon}
      </button>
    )
  }
)

export default HeaderButtonRound
