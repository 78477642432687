export const getInitialTheme = () => {
  let theme = localStorage.getItem('theme')

  if (!theme) {
    if (window && window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = 'dark'
      }
    }
  }

  return theme || 'light'
}
