import { Api } from '../../services/api'

export const undoRecentAction = (action: any, callback: any) => {
  if (action) {
    if (action.type === 'delete-note') {
      Api.createNote(action.data, action.options.boardId).then(() => {
        if (callback) {
          callback()
        }
      })
    }

    // TODO: undo edit/move note, undo delete column
  }
}
