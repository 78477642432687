import React from 'react'
import { text } from '../../../util/text'
import { Modal } from 'react-responsive-modal'
import '../../Buttons/ButtonGeneric/ButtonGeneric.scss'
import './TipsModal.scss'
import { useRootStore } from '../../../store/RootStateProvider'
import { observer } from 'mobx-react-lite'

const TipsModal: React.FC<{}> = observer(() => {
  const { uiStore } = useRootStore().rootStore

  return (
    <Modal
      open={uiStore.modalOpen === 'shortcuts'}
      onClose={uiStore.closeModal}
      center={true}
      showCloseIcon={true}
      classNames={{ modal: 'modal-tips' }}
    >
      <article>
        <h1 className="modal__title" tabIndex={0}>
          {text.tipsAndShortcuts}
        </h1>
        <div className="text-generic">
          <ul>
            <li>
              <kbd title="Escape">ESC</kbd> when editing text, to cancel your
              changes
            </li>
            <li>
              <kbd title="Escape">ESC</kbd> when moving a note, to put it back
            </li>
            <li>
              <span>
                <kbd title="Command">⌘</kbd>
                <span className="plus">{' + '}</span>
                <kbd>Enter</kbd>
              </span>{' '}
              on a Mac or <kbd title="Control">Ctrl</kbd>
              <span className="plus">{' + '}</span>
              <kbd>Enter</kbd> on Windows, to save the note you're editing
            </li>

            <li>
              <kbd title="Command">⌘</kbd>
              <span className="plus">{' + '}</span>
              <kbd>-</kbd> <span className="or">or</span>{' '}
              <kbd title="Control">Ctrl</kbd>
              <span className="plus">{' + '}</span>
              <kbd>-</kbd> to zoom out (really useful on large boards)
            </li>
            <li>
              <kbd>.</kbd> <span className="plus">+</span> <kbd>n</kbd> to add a
              note
            </li>
            <li>
              <kbd>.</kbd> <span className="plus">+</span> <kbd>c</kbd> to add a
              column
            </li>
            <li>
              <kbd>.</kbd> <span className="plus">+</span> <kbd>i</kbd> to
              import notes
            </li>
            <li>
              <kbd>.</kbd> <span className="plus">+</span> <kbd>e</kbd> to
              export notes in a CSV file
            </li>
            <li>
              <kbd>.</kbd> <span className="plus">+</span> <kbd>r</kbd> to set
              board size in pixels (handy if you need a lot of space)
            </li>
            <li>
              <kbd>.</kbd> <span className="plus">+</span> <kbd>z</kbd> to
              recreate the last note you deleted
            </li>
            <li>
              <kbd>.</kbd> <span className="plus">+</span> <kbd>t</kbd> to open
              this window
            </li>
          </ul>

          <h2 className="modal__title_secondary">{text.selection}</h2>
          <ul>
            <li>Click and drag on the board to select multiple notes</li>
            <li>
              <kbd>.</kbd> <span className="plus">+</span> <kbd>a</kbd> to
              select all notes on the board
            </li>
            <li>
              <kbd title="Command">⌘</kbd> or <kbd title="Control">Ctrl</kbd>{' '}
              <span className="plus">+</span> click a note to add or remove from
              selection
            </li>
            <li>
              <kbd title="Escape">ESC</kbd> to clear selection
            </li>
            <li>
              <kbd>Backspace</kbd> to delete selected notes
            </li>
          </ul>

          {/* <div className="modal-button-single">
              <ButtonGeneric
                text={text.close}
                onPress={uiStore.closeModal}
                type="secondary"
              />
            </div> */}
        </div>
      </article>
    </Modal>
  )
})

export default TipsModal
