import React from 'react'
import ColumnTitle from './ColumnTitle'
import { Api } from '../../services/api'
import { removeItemAtIndexFromArray } from '../../util/arrays'
import { text } from '../../util/text'
import { toast } from 'react-toastify'
import { Analytics } from '../../services/analytics'
import { observer } from 'mobx-react-lite'
import { useScroll } from './util/useScroll'
import { headerHeight } from '../../util/dimensions'
import { useRootStore } from '../../store/RootStateProvider'
import './Columns.scss'

interface ColumnsComponent {
  boardId: string
}

const Columns: React.FC<ColumnsComponent> = observer(({ boardId }) => {
  const { boardStore, uiStore } = useRootStore().rootStore
  const columns = boardStore.board?.columns || []

  const updateColumnTitle = (index: number, title: string) => {
    if (columns[index]) {
      columns[index] = title
    }

    Api.updateColumns(boardId, columns)
      .then(() => {})
      .catch((error) => {
        toast.error(text.updateColumnError)
        Analytics.log('update_columns', {
          success: false,
          columns,
          error,
          update_type: 'Update title',
        })
      })
  }

  const removeColumn = (index: number) => {
    if (uiStore.notificationOpen === 'add-column') {
      toast.dismiss()
      uiStore.setNotificationOpen('')
    }

    Api.updateColumns(boardId, removeItemAtIndexFromArray(columns, index))
      .then(() => {})
      .catch((error) => {
        toast.error(text.deleteColumnError)
        Analytics.log('update_columns', {
          success: false,
          columns,
          error,
          update_type: 'Delete column',
        })
      })
  }

  const { position } = useScroll()
  let titleOffsetTop = position.y > headerHeight ? position.y - headerHeight : 0

  return (
    <div className="columns">
      {columns.map((title, index) => {
        return (
          <div className="column" key={`col-${index}`}>
            <ColumnTitle
              removeColumn={removeColumn}
              index={index}
              updateColumnTitle={updateColumnTitle}
              title={title}
              style={{ transform: `translate3d(0, ${titleOffsetTop}px, 0)` }}
            />
          </div>
        )
      })}
      <div className="columns-disable-user-select"></div>
    </div>
  )
})

export default Columns
