import { observable, action, makeObservable } from 'mobx'

// interface UserSession {
//   id: string
// }

interface RecentAction {
  type: string
  data: any
  options: any
}

export class SessionStore {
  currentUser: any | null = null
  setCurrentUser = (user: any) => {
    this.currentUser = user
  }
  updateCurrentUserProfile = (data: any) => {
    this.currentUser = {
      ...this.currentUser,
      ...data,
    }
  }

  authStateChangedCallback: string = ''
  setAuthStateChangedCallback = (callback: 'save-board' | '') => {
    this.authStateChangedCallback = callback
  }

  repeatRegisteredVisit: boolean = false
  setRepeatRegisteredVisit = (value: boolean) => {
    this.repeatRegisteredVisit = value
  }

  recentActions: RecentAction[] = []
  saveRecentAction = (action: RecentAction) => {
    this.recentActions.unshift(action)

    if (this.recentActions.length > 50) {
      this.recentActions.pop()
    }
  }
  undoRecentAction = () => {
    this.recentActions.shift()
  }
  resetRecentActions = () => {
    this.recentActions = []
  }

  // Need persistence for this to work
  // hasPreviouslySignedIn: boolean = false
  // setHasPreviouslySignedIn = (has: boolean) => {
  //   this.hasPreviouslySignedIn = has
  // }

  // Don't need this now
  // isTouchScreen = false
  // setIsTouchScreen = (): boolean => {
  //   return (
  //     'ontouchstart' in window ||
  //     navigator.maxTouchPoints > 0 ||
  //     navigator.msMaxTouchPoints > 0
  //   )
  // }

  constructor() {
    makeObservable(this, {
      currentUser: observable,
      setCurrentUser: action,
      updateCurrentUserProfile: action,

      authStateChangedCallback: observable,
      setAuthStateChangedCallback: action,

      repeatRegisteredVisit: observable,
      setRepeatRegisteredVisit: action,

      recentActions: observable,
      saveRecentAction: action,
      undoRecentAction: action,
      resetRecentActions: action,

      // hasPreviouslySignedIn: observable,
      // setHasPreviouslySignedIn: action,

      // isTouchScreen: observable,
      // setIsTouchScreen: action,
    })
  }
}
