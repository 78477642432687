import React, { useState, useEffect } from 'react'
import { text } from '../../util/text'
import { ReactComponent as OfflineIcon } from './assets/icon_offline.svg'
import './assets/OfflineNotification.scss'

const OfflineNotification: React.FC<{}> = (): JSX.Element | null => {
  const [isOffline, setIsOffline] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('online', () => setIsVisible(false))
    window.addEventListener('offline', () => setIsOffline(true))

    return () => {
      window.addEventListener('online', () => setIsVisible(false))
      window.addEventListener('offline', () => setIsOffline(true))
    }
  }, [])

  useEffect(() => {
    if (isOffline) {
      setTimeout(() => {
        setIsVisible(true)
      }, 100)
    }
  }, [isOffline])

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setIsOffline(false)
      }, 250)
    }
  }, [isVisible])

  return isOffline ? (
    <div
      className={`offline-notification ${
        isVisible ? 'offline-notification_visible' : ''
      }`}
    >
      <OfflineIcon />
      <p>{text.youAreOffline}</p>
    </div>
  ) : null
}

export default OfflineNotification
