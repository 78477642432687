import React from 'react'
import { text } from '../../util/text'
import useHotKeys from '@reecelucas/react-use-hotkeys'
import './Footer.scss'
import AboutModal from './AboutModal/AboutModal'
import BoardSizeModal from './BoardSizeModal/BoardSizeModal'
import TipsModal from './TipsModal/TipsModal'
import { useRootStore } from '../../store/RootStateProvider'
import { observer } from 'mobx-react-lite'
import ProfileModal from '../Profile/ProfileModal'
import AuthModal from '../Auth/AuthModal'

interface Props {
  location: 'home' | 'board'
}

const Footer: React.FC<Props> = observer(({ location }) => {
  const authEnabled = process.env.REACT_APP_AUTH_ENABLED === 'true'
  const { sessionStore, boardStore, uiStore } = useRootStore().rootStore

  const boardOpen =
    !!sessionStore.currentUser && !!boardStore.board && !!boardStore.boardId

  const year = new Date().getFullYear()

  useHotKeys('.+r', () => {
    if (boardOpen) {
      if (!uiStore.modalOpen) {
        uiStore.setModalOpen('board-size')
      } else if (uiStore.modalOpen === 'board-size') {
        uiStore.closeModal()
      }
    }
  })

  useHotKeys('.+t', () => {
    if (boardOpen) {
      if (!uiStore.modalOpen) {
        uiStore.setModalOpen('shortcuts')
      } else if (uiStore.modalOpen === 'shortcuts') {
        uiStore.closeModal()
      }
    }
  })

  return (
    <div className="footer">
      <small>
        Copyright &copy; {year} by <b>Tryangly.com</b>
      </small>

      <span className="inline-text-separator"></span>
      <button className="link" onClick={() => uiStore.setModalOpen('about')}>
        {text.aboutTryangly}
      </button>

      {location === 'board' && (
        <>
          <span className="inline-text-separator"></span>
          <button
            className="link"
            onClick={() => uiStore.setModalOpen('shortcuts')}
          >
            {text.tipsAndShortcuts}
          </button>
        </>
      )}

      {/* <span className="inline-text-separator"></span>
        <button className="link" onClick={() => setBoardSizeModalOpen(true)}>
          {text.boardSize}
        </button> */}

      <AboutModal />
      <TipsModal />
      <BoardSizeModal />

      {authEnabled && <ProfileModal />}
      {authEnabled && <AuthModal />}
    </div>
  )
})

export default Footer
