import React, { useEffect, useState, useRef } from 'react'
import ContentEditable from 'react-contenteditable'
import {
  handleChangeSingleLineContentEditable,
  stripHtml,
} from '../../util/text'
import useHotKeys from '@reecelucas/react-use-hotkeys'
import './EditableText.scss'
import { alwaysAllowedKeys } from './util'

interface Props {
  doubleClick?: boolean
  text: string
  maxLength: number
  save: (text: string) => void
}

const EditableText: React.FC<Props> = (props) => {
  let contentEditableRef = useRef<HTMLElement>(null)
  let textRef = useRef(props.text)
  const [editing, setEditing] = useState(false)
  const [text, setText] = useState(props.text)

  const enableEditing = () => {
    if (!editing) {
      setEditing(true)
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e

    if (textRef.current.length >= props.maxLength) {
      if (alwaysAllowedKeys.indexOf(key) === -1) {
        e.preventDefault()
      }
    }

    if (key === 'Enter') {
      e.preventDefault()
      onBlur()
    }
  }

  const onChange = (e: any) => {
    let t = handleChangeSingleLineContentEditable(e.target.value)
    updateText(t.substr(0, props.maxLength))
  }

  const onBlur = () => {
    if (editing) {
      // Use textRef here because state.text is not up to date
      if (textRef.current) {
        if (textRef.current !== props.text) {
          let t = stripHtml(textRef.current)
          t = t.substr(0, props.maxLength)

          props.save(t)
        }
      } else {
        // Don't save if empty. Reset to initial state.
        updateText(props.text)
      }

      setEditing(false)
    }
  }

  const onFocus = () => {
    // Place the cursor at the end of text
    const s = window.getSelection()
    if (s) {
      s.setPosition(contentEditableRef.current, 1)
    }
  }

  const updateText = (text: string) => {
    setText(text)
    textRef.current = text
  }

  // ESC to cancel editing and go back to the initial text
  useHotKeys('Escape', () => {
    if (editing) {
      updateText(props.text)
      onBlur()
    }
  })

  useEffect(() => {
    updateText(props.text)
  }, [props.text])

  useEffect(() => {
    // Chrome doesn't get the focus right without this
    if (editing) {
      if (contentEditableRef && contentEditableRef.current) {
        contentEditableRef.current.focus()
      }
    }
  }, [editing])

  return (
    <div
      className={`editable ${editing ? 'editable_editing' : ''}`}
      onClick={props.doubleClick ? () => null : enableEditing}
      onDoubleClick={props.doubleClick ? enableEditing : () => null}
    >
      <ContentEditable
        disabled={!editing}
        html={text}
        innerRef={contentEditableRef}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}

export default EditableText
