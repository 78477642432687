import findIndex from 'lodash/findIndex'

export const removeItemAtIndexFromArray = (
  array: string[],
  index: number
): string[] => {
  if (index > -1) {
    return [...array.slice(0, index), ...array.slice(index + 1)]
  }

  return array
}

export const replaceObjectInArray = (
  array: Array<any>,
  item: any //{ id: string }
): any[] => {
  const index = findIndex(array, { id: item.id })

  if (index > -1) {
    return [...array.slice(0, index), item, ...array.slice(index + 1)]
  }

  return array
}
