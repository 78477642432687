import React, { useState } from 'react'
import { text } from '../../../util/text'
import { ReactComponent as ShowPasswordIcon } from './assets/icon_show-password.svg'
import './assets/PasswordInput.scss'

interface Props {
  id: string
  password: string
  onChange: any
  invalid: boolean
  placeholder?: string
  disabled?: boolean
}

const PasswordInput: React.FC<Props> = ({
  id,
  password,
  onChange,
  invalid,
  placeholder,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div
      className={`input-container input_password ${
        invalid ? 'input_invalid' : ''
      }`}
    >
      <input
        type={showPassword ? 'text' : 'password'}
        id={id}
        value={password}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />

      <button
        type="button"
        className={`password-hiddenword-button ${
          showPassword && 'password-hiddenword-button__active'
        }`}
        onClick={() => setShowPassword(!showPassword)}
        title={showPassword ? text.hidePassword : text.showPassword}
      >
        <span>
          <ShowPasswordIcon />
        </span>
      </button>
    </div>
  )
}

PasswordInput.defaultProps = {
  placeholder: '',
  disabled: false,
}

export default PasswordInput
