import React, { useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import { DnDItemTypes } from '../DnD/DnDItemTypes'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useRootStore } from '../../store/RootStateProvider'
import { Api } from '../../services/api'
import { observer } from 'mobx-react-lite'
import { text } from '../../util/text'
import ButtonRemove from '../Buttons/ButtonRemove/ButtonRemove'
import {
  getDraggableNoteStyles,
  NoteDraggableInterface,
  getNoteClassNames,
} from './util'
import NoteEditable from './NoteEditable'
import './Note.scss'

const Note: React.FC<NoteDraggableInterface> = observer((props) => {
  const { sessionStore } = useRootStore().rootStore
  const currentUser = sessionStore.currentUser?.id || ''

  const [visible, setVisible] = useState(false)
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [disableTransition, setDisableTransition] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  // Note is Locked when it is being dragged or edited by another user.
  // It can't be edited or moved by anyone else.
  const locked = !!(props.lockedByUser && props.lockedByUser !== currentUser)

  // useDrag Return Value Array:
  // [0] collected props,
  // [1] connector function for the drag source
  // [2] connector function for the drag preview
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DnDItemTypes.NOTE,
      id: props.id,
      color: props.color,
      text: props.text,
      left: props.left,
      top: props.top,
      drawer: props.drawer,
    },
    begin: () => {
      setDisableTransition(true)
      setNoteLock(true)
    },
    end: () => {
      setTimeout(() => {
        if (isMounted) {
          setDisableTransition(false)
        }
      }, 500)
    },
    canDrag: () => !locked,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const onPress = (e: any) => {
    if (e.target.className === 'button-remove__inner') {
      if (!editing) {
        setDeleting(true)
        deleteNote()
      }
    } else {
      if (!locked && !editing && !deleting) {
        if ((e.metaKey || e.ctrlKey) && props.toggleNoteSelected) {
          // Selection currently unavailable in the drawer
          if (!props.drawer) {
            props.toggleNoteSelected(props.id)
          }
        } else {
          setEditing(true)
          setNoteLock(true)
        }
      }
    }
  }

  const deleteNote = () => {
    // Remove from selection before deleting the note
    // (would crash otherwise)
    if (props.selected && props.toggleNoteSelected) {
      props.toggleNoteSelected(props.id)
    }

    Api.deleteNote(props.id, props.boardId).then(() => {
      sessionStore.saveRecentAction({
        type: 'delete-note',
        data: {
          id: props.id,
          text: props.text,
          color: props.color,
          createdAt: props.createdAt,
          left: props.left,
          top: props.top,
          zIndex: props.zIndex,
          drawer: props.drawer,
          drawerTimestamp: props.drawerTimestamp,
        },
        options: {
          boardId: props.boardId,
        },
      })
    })
  }

  // Lock the note so it can't be modified by anyone else
  // Lock will be released on drop (Board.tsx) or blur (Note.tsx)
  const setNoteLock = (lock: boolean) => {
    if (currentUser && !deleting) {
      Api.updateNote(
        props.id,
        { lockedByUser: lock ? currentUser : '' },
        props.boardId
      )
        .then(() => {})
        .catch(() => {})
    }
  }

  const preventUnloadWhenEditing = (e: any) => {
    if (editing || deleting) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  // Prevents attempted React state update on an unmounted component,
  // when a note is dragged from the board to the drawer
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  useEffect(() => {
    if (!visible) {
      setVisible(true)
    }
  }, [visible])

  useEffect(() => {
    window.addEventListener('beforeunload', preventUnloadWhenEditing)

    return () =>
      window.removeEventListener('beforeunload', preventUnloadWhenEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing])

  return (
    <div
      id={`note-${props.id}`}
      className={getNoteClassNames(
        editing,
        visible,
        disableTransition,
        locked,
        props.selected
      )}
      data-draggable={true}
      onClick={onPress}
      ref={editing ? null : drag}
      style={getDraggableNoteStyles(
        props.left,
        props.top,
        isDragging,
        props.zIndex
      )}
      title={locked ? text.lockedNoteTitle : ''}
    >
      <NoteEditable {...props} editing={editing} setEditing={setEditing} />

      <div className="button-delete-note">
        <ButtonRemove label={text.removeNote} />
      </div>
    </div>
  )
})

Note.defaultProps = {
  selected: false,
}

export default Note
