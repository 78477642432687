import React from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'
import { DnDItemTypes } from './DnDItemTypes'
import NoteDragPreview from '../Note/NoteDragPreview'
import './BoardDragLayer.scss'
import SelectionDragPreview from '../Board/Selection/SelectionDragPreview'

const getItemStyles = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  let { x, y } = currentOffset

  return {
    transform: `translate(${x}px, ${y}px)`,
  }
}

const BoardDragLayer: React.FC<{}> = (): JSX.Element | null => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))

  const renderItem = (): React.ReactNode => {
    switch (itemType) {
      case DnDItemTypes.NOTE:
        return <NoteDragPreview {...item} />

      case DnDItemTypes.SELECTION:
        return <SelectionDragPreview {...item} />

      default:
        return null
    }
  }

  if (!isDragging) {
    return null
  }

  return (
    <div className="board-drag-layer">
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  )
}

export default BoardDragLayer
