import React from 'react'
import ContentEditable from 'react-contenteditable'
import { NoteInterface } from './util'
import './Note.scss'

export type NotePreviewInterface = Pick<NoteInterface, 'color' | 'text'>

const NoteDragPreview: React.FC<NotePreviewInterface> = ({ text, color }) => {
  return (
    <div className="note-drag-preview">
      <div className="note_editable" style={{ backgroundColor: color }}>
        <div className="note_editable__inner">
          <ContentEditable
            className="note_editable__text"
            disabled={true}
            html={text}
            onChange={() => null}
          />
        </div>
      </div>
    </div>
  )
}

export default NoteDragPreview
