import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Api } from '../../services/api'
import { Analytics } from '../../services/analytics'
import { useRootStore } from '../../store/RootStateProvider'
import ButtonGeneric from '../Buttons/ButtonGeneric/ButtonGeneric'
import { text } from '../../util/text'
import './assets/Auth.scss'
import { toast } from 'react-toastify'
import PasswordInput from '../Forms/PasswordInput/PasswordInput'
import ResetPasswordModal from './ResetPassword/ResetPasswordModal'
import MultiLineToast from '../Toastify/MultiLineToast'

const initialEmail =
  process.env.NODE_ENV === 'development' ? 'artstk@gmail.com' : ''
const initialPassword = process.env.NODE_ENV === 'development' ? '123123' : ''

interface Props {
  signInEnabled: boolean
}

const Auth: React.FC<Props> = observer(({ signInEnabled }) => {
  const { uiStore, sessionStore } = useRootStore().rootStore

  const [email, setEmail] = useState(initialEmail)
  const [password, setPassword] = useState(initialPassword)

  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [busy, setBusy] = useState(false)

  const onSubmit = (e: any) => {
    e.preventDefault()

    if (!busy) {
      setBusy(true)
    }

    setErrorMsg('')

    if (signInEnabled) {
      signIn()
    } else {
      signUp()
    }
  }

  const onChangeEmail = (e: any) => {
    setInvalidEmail(false)

    // setEmail(e.target.value.substr(0, maxCharacters.username))
    setEmail(e.target.value)
  }

  const onChangePassword = (e: any) => {
    setInvalidPassword(false)

    setPassword(e.target.value)
  }

  const signUp = () => {
    Api.signUp(email.trim(), password)
      .then((userCredential: any) => {
        // This does not trigger onAuthStateChanged,
        // so update the store profile here
        sessionStore.updateCurrentUserProfile({
          email,
          isAnonymous: false,
        })
        authCallback('signup', userCredential, true)

        // const emailVerificationSent = text.emailVerificationSent(email)
        toast.success(
          <MultiLineToast
            lines={[text.signUpSuccess, text.emailVerificationSent]}
          />
        )

        Api.sendVerificationEmail()
      })
      .catch((error: any) => {
        authCallback('signup', null, false, error)
      })
  }

  const signIn = () => {
    Api.signIn(email.trim(), password)
      .then((userCredential: any) => {
        authCallback('signin', userCredential, true)
      })
      .catch((error: any) => {
        authCallback('signin', null, true, error)
      })
  }

  const authCallback = (
    type: string,
    userCredential: any,
    success: boolean,
    error?: any
  ) => {
    setBusy(false)

    if (success && userCredential && userCredential.user) {
      const uid = userCredential.user.uid

      if (!sessionStore.repeatRegisteredVisit) {
        // Currently duplicated because MobX store is not persisted
        localStorage.setItem('repeatRegisteredVisit', 'true')
        sessionStore.setRepeatRegisteredVisit(true)
      }

      Analytics.setUserId(uid)
      Analytics.logUserAuth({
        type,
        anonymous: false,
        success: true,
        id: uid,
      })

      uiStore.setAuthModalOpen(false)
    } else {
      setError(error)

      Analytics.logUserAuth({
        type,
        anonymous: false,
        success: false,
        error,
      })
    }
  }

  const setError = (error: any) => {
    if (error && error.message) {
      switch (error.code) {
        // case 'auth/invalid-email':
        // case 'auth/too-many-requests':
        // case 'auth/user-not-found':
        // case 'auth/email-already-in-use':

        case 'auth/wrong-password':
          setInvalidPassword(true)
          setErrorMsg(text.passwordInvalid)
          break

        case 'auth/weak-password':
          setInvalidPassword(true)
          setErrorMsg(error.message)
          break

        default:
          setInvalidEmail(true)
          setErrorMsg(error.message)
          break
      }
    } else {
      setErrorMsg(text.errorGeneric)
    }
  }

  useEffect(() => {
    return () => {
      sessionStore.setAuthStateChangedCallback('')
    }
  }, [sessionStore])

  return (
    <form onSubmit={onSubmit}>
      <label htmlFor="auth-email">{text.email}</label>
      <div className={`input-container ${invalidEmail ? 'input_invalid' : ''}`}>
        <input
          type="email"
          id="auth-email"
          value={email}
          onChange={onChangeEmail}
        />
      </div>

      <label htmlFor="auth-password">{text.password}</label>
      <PasswordInput
        id="auth-password"
        password={password}
        onChange={onChangePassword}
        invalid={invalidPassword}
      />

      {errorMsg ? <p className="form-error-msg">{errorMsg}</p> : null}

      <div className="modal-buttons__stack">
        <ButtonGeneric
          htmlButtonType="submit"
          text={signInEnabled ? text.signIn : text.signUp}
          onPress={onSubmit}
          disabled={busy}
        />
      </div>

      <p className="text-generic text-centered top-spacing-medium">
        <button
          className="link"
          type="button"
          onClick={() => uiStore.setSecondaryModalOpen('reset-password')}
        >
          {text.resetPassword}
        </button>
      </p>

      <ResetPasswordModal initialEmail={email} />
    </form>
  )
})

export default Auth
