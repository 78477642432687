import React, { useState, useEffect } from 'react'
import AppHeader from '../AppHeader/AppHeader'
import {
  toggleAnimatedBoardResize,
  getBoardIdFromLocation,
  updateWindowTitle,
} from '../../util/navigation'
import { Api } from '../../services/api'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../store/RootStateProvider'
import { isTouchScreen } from '../../util/touch'
import CreateBoard from '../CreateBoard/CreateBoard'
import Footer from '../Footer/Footer'
import Preloader from './Preloader/Preloader'
import Wall from '../Wall/Wall'
import { BoardInterface } from '../../util/interfaces'
import { ReactComponent as BgImage } from './assets/bg_home.svg'
import './assets/Home.scss'

interface HomeProps {
  history: {
    listen: (listener: any) => void
  }
}

const Home: React.FC<HomeProps> = observer(({ history }) => {
  const { boardStore, savedBoardsStore, sessionStore, uiStore } =
    useRootStore().rootStore

  let { board, boardId } = boardStore
  if (board && board.deleted) {
    board = null
  }

  useEffect(() => {
    const unlistenHistory = history.listen((location: Location) => {
      toggleAnimatedBoardResize()

      boardStore.setBoardId(getBoardIdFromLocation(location))
    })

    return () => unlistenHistory
  }, [history, boardStore])

  const changeBoard = (boardData: BoardInterface | null) => {
    boardStore.setBoardId(boardData ? boardId : null) // TODO: check this
    boardStore.setBoard(boardData)

    if (boardData) {
      updateWindowTitle(boardData.title)
      boardStore.updateMinimumBoardDimensionsForColumns()
    } else {
      updateWindowTitle(null)
      boardStore.resetMinimumBoardDimensions()

      uiStore.resetStore()
    }
  }

  // Auth was triggered by SavedBoardToggle:
  // 1. Anonymous is prompted to sign in
  // 2. Signs in/up
  // 3. Board is auto-saved here
  const saveBoardAfterAuth = () => {
    if (boardStore.board && boardStore.boardId) {
      const b = {
        id: boardStore.boardId,
        title: boardStore.board.title,
        owned: boardStore.board.owner?.id === sessionStore.currentUser.id,
      }

      Api.saveBoard(b).then(() => {
        savedBoardsStore.saveBoard(b)
      })
    }
  }

  useEffect(() => {
    toggleAnimatedBoardResize()
  }, [])

  useEffect(() => {
    if (isTouchScreen()) {
      document.body.classList.add('touch-screen')
    }

    Api.setupAuthListener((user: any) => {
      if (user) {
        if (user.isAnonymous) {
          // Logged in anonymous
        } else {
          // Logged in registered user

          if (sessionStore.authStateChangedCallback === 'save-board') {
            saveBoardAfterAuth()
            sessionStore.setAuthStateChangedCallback('')
          }
        }

        sessionStore.setCurrentUser({
          id: user.uid,
          email: user.email,
          displayName: user.displayName,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
        })
      } else {
        // Logged out
        sessionStore.setCurrentUser(null)

        Api.authenticateAnonymously()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore, savedBoardsStore])

  useEffect(() => {
    const user = sessionStore.currentUser
    let unsubscribeUsersBoards: any = null

    if (user && user.id) {
      unsubscribeUsersBoards = Api.streamUsersBoards({
        next: (doc: any) => {
          const boardsData = doc.data()

          const boards = boardsData || []
          savedBoardsStore.setBoards(boards)
        },
        error: () => {},
      })
    } else {
      if (unsubscribeUsersBoards) {
        unsubscribeUsersBoards()
      }
    }

    return () => {
      if (unsubscribeUsersBoards) {
        unsubscribeUsersBoards()
      }
    }
  }, [sessionStore.currentUser, savedBoardsStore])

  useEffect(() => {
    sessionStore.setRepeatRegisteredVisit(
      localStorage.getItem('repeatRegisteredVisit') === 'true'
    )
  }, [sessionStore])

  const [renderHome, setRenderHome] = useState(!boardId)
  const [homeVisible, setHomeVisible] = useState(!boardId)
  const [renderWall, setRenderWall] = useState(!!boardId)
  const [wallVisible, setWallVisible] = useState(!!boardId)

  useEffect(() => {
    if (boardId) {
      document.body.classList.remove('home-page')

      setHomeVisible(false)
      setRenderWall(true)

      setTimeout(() => {
        setRenderHome(false)
        setWallVisible(true)
      }, 300)
    } else {
      document.body.classList.add('home-page')

      setRenderHome(true)
      setWallVisible(false)
      setRenderWall(false)

      setTimeout(() => {
        setHomeVisible(true)
        setRenderWall(false)

        changeBoard(null)
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId])

  if (sessionStore.currentUser) {
    if (renderHome) {
      return (
        <div className={`home ${homeVisible ? 'home_visible' : ''}`}>
          <div className="home-page-background">
            <BgImage role="decoration" />
          </div>

          <AppHeader boardId={boardId} />

          <CreateBoard />
          <Footer location="home" />
        </div>
      )
    }

    if (renderWall) {
      return <Wall visible={wallVisible} changeBoard={changeBoard} />
    }

    return null
  } else {
    return <Preloader />
  }
})

export default Home
