import React from 'react'
import { ToastContainer } from 'react-toastify'
import ToastifyCloseButton from './ToastifyCloseButton'
import { toastAutoCloseTime } from '../../util/config'
import './assets/scss/main.scss'

const ToastRenderer = () => {
  return (
    <ToastContainer
      autoClose={toastAutoCloseTime}
      closeButton={ToastifyCloseButton}
      hideProgressBar={true}
    />
  )
}

export default ToastRenderer
