import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Modal } from 'react-responsive-modal'
import { useRootStore } from '../../store/RootStateProvider'
import 'react-responsive-modal/styles.css'
import SignInOrSignUp from './SignInOrSignUp'

const AuthModal: React.FC<{}> = observer((): JSX.Element | null => {
  const { uiStore } = useRootStore().rootStore
  const { authModalOpen, setAuthModalOpen } = uiStore

  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    if (authModalOpen) {
      setRendered(true)
    } else {
      setTimeout(() => {
        setRendered(false)
      }, 500)
    }
  }, [authModalOpen])

  return rendered ? (
    <Modal
      open={authModalOpen}
      classNames={{ modal: 'modal-auth' }}
      onClose={() => setAuthModalOpen(false)}
      center={true}
    >
      <SignInOrSignUp />
    </Modal>
  ) : null
})
export default AuthModal
