import React, { useContext } from 'react'
import { text } from '../../../util/text'
import { Modal } from 'react-responsive-modal'
import ButtonGeneric from '../../Buttons/ButtonGeneric/ButtonGeneric'
import '../../Buttons/ButtonGeneric/ButtonGeneric.scss'
import UserThumbnail from '../../UserThumbnail/UserThumbnail'
import { ThemeContext } from '../../Theme/ThemeProvider'
import { useRootStore } from '../../../store/RootStateProvider'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import 'react-responsive-modal/styles.css'
import './AboutModal.scss'

const AboutModal: React.FC<{}> = observer(() => {
  const history = useHistory()
  const { theme } = useContext(ThemeContext)
  const { uiStore } = useRootStore().rootStore

  const onClickCreateBoard = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    uiStore.closeModal()

    if (window.location.pathname !== '/') {
      history.push('/')
    }
  }

  return (
    <Modal
      open={uiStore.modalOpen === 'about'}
      onClose={uiStore.closeModal}
      center={true}
      showCloseIcon={false}
      classNames={{ modal: 'modal-about' }}
    >
      <article className="vcard" id="vcard-tryangly">
        <h1 className="modal__title" tabIndex={0}>
          About <span className="fn org">Tryangly</span>
        </h1>
        <div className="text-generic note">
          <p>
            Tryangly is an excellent tool for your planning sessions. Whether
            you’re trying to organise your own matters, or need to collaborate
            real-time with a team, Tryangly is a fun way to work on your ideas
            or conduct a meeting.
          </p>

          <p>
            Go ahead,{' '}
            <a href="/" onClick={onClickCreateBoard}>
              create your own board
            </a>{' '}
            and share it with others.
          </p>

          <section className="built-by">
            <h2 className="modal__title">{text.builtBy}</h2>
            <div>
              <div
                id="vcard-bartek"
                className="vcard"
                itemScope={true}
                itemType="http://schema.org/Person"
              >
                <a
                  href="https://bstankowski.pl"
                  target="_blank"
                  rel="noreferrer"
                  itemProp="url"
                  className="url"
                >
                  <UserThumbnail dark={theme !== 'dark'} />
                  <div className="fn" itemProp="name">
                    <span>Bartek</span> Stańkowski
                  </div>
                  <span className="hide title" itemProp="jobTitle">
                    Developer
                  </span>
                </a>
              </div>

              <div
                id="vcard-dawid"
                className="vcard"
                itemScope={true}
                itemType="http://schema.org/Person"
              >
                <a
                  href="http://kwasniewski.info"
                  target="_blank"
                  rel="noreferrer"
                  itemProp="url"
                  className="url"
                >
                  <div className="fn" itemProp="name">
                    <span>Dawid</span> Kwaśniewski
                  </div>
                  <UserThumbnail dark={theme !== 'dark'} />
                  <span className="hide title" itemProp="jobTitle">
                    Designer
                  </span>
                </a>
              </div>
            </div>
          </section>

          <div className="modal-buttons__row modal-buttons__row_2">
            <div>
              <a
                href="mailto:tryangly.app@gmail.com"
                className="button-generic email"
              >
                {text.contactUs}
              </a>
            </div>

            <div>
              <ButtonGeneric
                text={text.close}
                onPress={uiStore.closeModal}
                type="secondary"
              />
            </div>
          </div>
        </div>
      </article>
    </Modal>
  )
})

export default AboutModal
