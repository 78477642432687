import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Api } from '../../../services/api'
import { useRootStore } from '../../../store/RootStateProvider'
import { maxCharacters } from '../../../util/config'
import ButtonGeneric from '../../Buttons/ButtonGeneric/ButtonGeneric'
import { text } from '../../../util/text'
import ModalTitle from '../../ModalTitle/ModalTitle'

interface Props {
  saveCallback?: Function
}

const ProfileName: React.FC<Props> = observer(({ saveCallback }) => {
  const { sessionStore } = useRootStore().rootStore
  const user = sessionStore.currentUser

  const [name, setName] = useState(user?.displayName || '')
  const [invalidName, setInvalidName] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [busy, setBusy] = useState(false)

  const onChangeName = (e: any) => {
    if (invalidName) {
      setInvalidName(false)
    }

    setName(e.target.value.substr(0, maxCharacters.username))
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    if (busy) {
      return
    }

    if (!name) {
      setInvalidName(true)
      return
    }

    setErrorMsg('')

    // const displayName = stripHtml(name)
    if (name !== user.displayName) {
      setBusy(true)

      Api.updateUserProfile({
        displayName: name,
      })
        .then(() => {
          sessionStore.updateCurrentUserProfile({ displayName: name })

          Api.updateUserPresence(user.id, {
            displayName: name,
          })

          setBusy(false)

          if (saveCallback) {
            saveCallback()
          }
        })
        .catch((error) => {
          setErrorMsg(error.message)
          setBusy(false)
        })
    } else {
      if (saveCallback) {
        saveCallback()
      }
    }
  }

  return (
    <section>
      <ModalTitle text={text.changeName} />

      <form onSubmit={onSubmit}>
        <label htmlFor="profile-name">{text.yourName}</label>
        <div
          className={`input-container ${invalidName ? 'input_invalid' : ''}`}
        >
          <input
            type="text"
            id="profile-name"
            value={name}
            onChange={onChangeName}
            disabled={busy}
          />
        </div>

        {errorMsg ? <p className="form-error-msg">{errorMsg}</p> : null}

        <div className="modal-buttons__stack">
          <ButtonGeneric
            htmlButtonType="submit"
            text={text.changeName}
            onPress={onSubmit}
            disabled={busy}
          />
        </div>
      </form>
    </section>
  )
})

export default ProfileName
