import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { useRootStore } from '../../store/RootStateProvider'
import { text } from '../../util/text'
import Tab from '../Tabs/Tab'
import ProfileBoards from './ProfileBoards/ProfileBoards'
import Profile from './Profile'

const ProfileModal: React.FC<{}> = observer(() => {
  const { uiStore } = useRootStore().rootStore
  const [activeTab, setActiveTab] = useState('profile')

  return (
    <Modal
      open={uiStore.modalOpen === 'profile'}
      classNames={{ modal: 'modal-profile' }}
      onClose={uiStore.closeModal}
      center={true}
    >
      <div className="tabs">
        <Tab
          active={activeTab === 'profile'}
          text={text.yourProfile}
          onPress={() => setActiveTab('profile')}
        />

        <Tab
          active={activeTab === 'boards'}
          text={text.boards}
          onPress={() => setActiveTab('boards')}
        />
      </div>

      {activeTab === 'profile' && <Profile />}
      {activeTab === 'boards' && <ProfileBoards />}
    </Modal>
  )
})

export default ProfileModal
