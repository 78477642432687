export const getClassNames = (type?: string, disabled?: boolean): string => {
  let c = ''

  if (type === 'delete') {
    c += ' button-generic_delete'
  }

  if (type === 'cancel') {
    c += ' button-generic_cancel'
  }

  if (type === 'secondary') {
    c += ' button-generic_secondary'
  }

  if (disabled) {
    c += ' button-generic_disabled'
  }

  return c
}
