import { observer } from 'mobx-react-lite'
import React from 'react'
import { Modal } from 'react-responsive-modal'
import { useRootStore } from '../../../../store/RootStateProvider'
import { text } from '../../../../util/text'
import ButtonGeneric from '../../../Buttons/ButtonGeneric/ButtonGeneric'

const AuthPromptModal: React.FC<{}> = observer(() => {
  const { uiStore, sessionStore } = useRootStore().rootStore

  const onPressAuth = () => {
    uiStore.setAuthModalOpen(true)
    uiStore.closeModal()
    sessionStore.setAuthStateChangedCallback('save-board')
  }

  return (
    <Modal
      open={uiStore.modalOpen === 'save-board-auth-prompt'}
      onClose={uiStore.closeModal}
      center={true}
      showCloseIcon={false}
      classNames={{ modal: 'modal-alert' }}
    >
      <section>
        <h2 className="modal__title" tabIndex={0}>
          {text.savedBoards}
        </h2>
        <p className="text-generic">{text.savedBoardAuthPrompt}</p>

        <div className="modal-buttons__stack">
          <ButtonGeneric
            htmlButtonType="submit"
            text={text.signIn}
            onPress={onPressAuth}
          />

          <ButtonGeneric
            text={text.cancel}
            type="cancel"
            onPress={uiStore.closeModal}
          />
        </div>
      </section>
    </Modal>
  )
})

export default AuthPromptModal
