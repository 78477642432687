// import forEach from 'lodash/forEach'
import { observable, action, makeObservable } from 'mobx'
import { Box } from 'react-drag-to-select'

export class SelectionStore {
  selecting: boolean = false
  setSelecting = (selecting: boolean) => {
    this.selecting = selecting
  }

  selectionBox: Box | null = null
  setSelectionBox = (box: Box | null) => {
    this.selectionBox = box
  }

  constructor() {
    makeObservable(this, {
      selecting: observable,
      setSelecting: action,

      selectionBox: observable,
      setSelectionBox: action,
    })
  }
}
