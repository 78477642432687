import React, { useEffect, useState, useRef } from 'react'
import ContentEditable from 'react-contenteditable'
import { toast } from 'react-toastify'
import useHotKeys from '@reecelucas/react-use-hotkeys'
import { Api } from '../../services/api'
import './Note.scss'
import { text } from '../../util/text'
import { NoteInterface } from './util'

const NoteEditable: React.FC<NoteInterface> = (props) => {
  let contentEditableRef = useRef<HTMLElement>(null)
  let textRef = useRef(props.text)
  const [noteText, setNoteText] = useState(props.text)

  const onBlur = () => {
    if (props.setEditing) {
      if (props.editing) {
        // Clear text selection on blur, becuase if you
        // select text in one note and start start dragging
        // another one, te selected text will follow
        // the other note while it's moving
        const s = window.getSelection()
        if (s && s.empty) {
          s.empty()
        }

        updateNote()
        props.setEditing(false)
      }
    }
  }

  const onFocus = () => {
    // Place the cursor at the end of text
    const s = window.getSelection()
    if (s) {
      s.setPosition(contentEditableRef.current, 1)
    }
  }

  const onChange = (e: any) => updateNoteText(e.target.value)

  const updateNote = () => {
    Api.updateNote(
      props.id,
      {
        text: textRef.current,
        lockedByUser: '', // Release lock created in Note.tsx
      },
      props.boardId
    ).catch(() => {
      toast.error(text.updateNoteError)
    })
  }

  const updateNoteText = (text: string) => {
    setNoteText(text)
    textRef.current = text
  }

  // ESC to cancel editing
  useHotKeys('Escape', () => {
    if (props.editing) {
      updateNoteText(props.text)
      onBlur()
    }
  })

  // ⌘+Enter to save
  useHotKeys(['Meta+Enter', 'Control+Enter'], () => {
    if (props.editing) {
      onBlur()
    }
  })

  useEffect(() => {
    if (props.editing) {
      if (contentEditableRef.current) {
        contentEditableRef.current.focus()
      }
    }
  }, [props.editing])

  useEffect(() => {
    updateNoteText(props.text)
  }, [props.text])

  return (
    <div className="note_editable" style={{ backgroundColor: props.color }}>
      <div className="note_editable__inner">
        <ContentEditable
          className="note_editable__text"
          data-testid="note__contenteditable"
          disabled={!props.editing}
          html={noteText}
          innerRef={contentEditableRef}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default NoteEditable
