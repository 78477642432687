import { observable, action, makeObservable, computed } from 'mobx'
import { BoardInterface } from '../util/interfaces'
import { getBoardIdFromLocation } from '../util/navigation'
import { minimumBoardDimensions } from '../util/dimensions'
import { minColumnWidth } from '../components/Columns/util/util'

export class BoardStore {
  boardId: string | null = getBoardIdFromLocation(window.location)
  setBoardId = (id: string | null) => {
    this.boardId = id
  }

  board: BoardInterface | null = null
  setBoard = (board: BoardInterface | null) => {
    this.board = board
  }

  get columnsCount() {
    return this.board?.columns.length || 0
  }

  updateBoardDimensions = (width: number, height: number) => {
    if (this.board) {
      this.board.width = width
      this.board.height = height
    }
  }

  minimumBoardWidth: number = minimumBoardDimensions.width
  minimumBoardHeight: number = minimumBoardDimensions.height

  updateMinimumBoardDimensionsForColumns = () => {
    let minWidth = 0
    let minHeight = this.minimumBoardHeight

    if (this.board) {
      if (this.board.columns.length) {
        minWidth = Math.max(
          this.board.columns.length * minColumnWidth + 120, // 120 = toolbar+paddings
          minimumBoardDimensions.width
        )
      } else {
        minWidth = minimumBoardDimensions.width
      }

      this.minimumBoardWidth = minWidth
      this.minimumBoardHeight = minHeight
    }
  }

  setMinimumBoardDimensions = (minWidth: number, minHeight: number) => {
    this.minimumBoardWidth = minWidth
    this.minimumBoardHeight = minHeight
  }

  resetMinimumBoardDimensions = () => {
    this.minimumBoardWidth = minimumBoardDimensions.width
    this.minimumBoardHeight = minimumBoardDimensions.height
  }

  constructor() {
    makeObservable(this, {
      boardId: observable,
      setBoardId: action,

      board: observable,
      setBoard: action,

      columnsCount: computed,

      updateBoardDimensions: action,

      minimumBoardWidth: observable,
      minimumBoardHeight: observable,
      updateMinimumBoardDimensionsForColumns: action,
      setMinimumBoardDimensions: action,
      resetMinimumBoardDimensions: action,
    })
  }
}
