import React from 'react'
import { RootStore } from './RootStore'
import { configure } from 'mobx'

configure({
  enforceActions: 'observed',
  //   computedRequiresReaction: true,
  //   reactionRequiresObservable: true,
  //   observableRequiresReaction: true,
  // disableErrorBoundaries: true,
})

interface RootStateContextValue {
  rootStore: RootStore
}

export const RootStateContext = React.createContext<RootStateContextValue>(
  {} as RootStateContextValue
)

const rootStore = new RootStore()

export const RootStateProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <RootStateContext.Provider value={{ rootStore }}>
      {children}
    </RootStateContext.Provider>
  )
}

export const useRootStore = () => React.useContext(RootStateContext)

/* HOC to inject store to any functional or class component */
// export const withStore = (Component: any) => (props: any) => {
//   return <Component {...props} store={useRootStore()} />
// }
