import React, { useState, useEffect } from 'react'
import './assets/CookiesNotification.scss'
import ButtonGeneric from '../Buttons/ButtonGeneric/ButtonGeneric'

const CookiesNotification: React.FC<{}> = (): JSX.Element | null => {
  const [accepted, setAccepted] = useState(
    localStorage.getItem('cookiesAccepted')
  )
  const [visible, setVisible] = useState(false)

  const onPressClose = () => {
    localStorage.setItem('cookiesAccepted', 'true')
    setVisible(false)

    setTimeout(() => {
      setAccepted('true')
    }, 500)
  }

  useEffect(() => {
    if (accepted) {
    } else {
      setVisible(true)
    }
  }, [accepted])

  return accepted ? null : (
    <div
      className={`cookies-notification ${
        visible ? 'cookies-notification_visible' : ''
      }`}
    >
      <p>
        By using Tryangly you agree to our use of Cookies and similar
        technologies, to remember your preferences and repeat visits in your
        browser.
      </p>

      <ButtonGeneric text="OK" onPress={onPressClose} />
    </div>
  )
}

export default CookiesNotification
