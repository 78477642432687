import React from 'react'
import { ReactComponent as SeparatorImage } from './img_headerSeparator.svg'
import './AppHeaderSeparator.scss'

const AppHeaderSeparator: React.FC<{}> = () => {
  return (
    <div className="app-header__logo-separator">
      <SeparatorImage />
    </div>
  )
}

export default AppHeaderSeparator
