export const getRandomColor = (): string => {
  return noteColors[Math.floor(Math.random() * noteColors.length)]
}

export const noteColors: string[] = [
  '#F78080',
  '#E892C0',
  '#CC9EF4',
  '#7F90EF',
  '#8CD6ED',
  '#A8E8E1',
  '#93BF93',
  '#FDE194',
  '#FFA56E',
]

export const defaultNotePosition = {
  top: 70,
  left: 30,
}

export const getDraggableNoteStyles = (
  left: number,
  top: number,
  isDragging: boolean,
  zIndex: number
): Object => {
  const transform = `translate3d(${left}px, ${top}px, 0)`

  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,

    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
    zIndex,
  }
}

export interface NoteInterface {
  id: string
  boardId: string
  color: string
  text: string
  editing?: boolean
  setEditing?: (editing: boolean) => void
}

export interface NoteDraggableInterface {
  boardId: string
  id: string
  color: string
  text: string
  left: number
  top: number
  zIndex: number
  drawer?: boolean
  lockedByUser?: string
  drawerTimestamp?: string
  createdAt?: string
  selected: boolean
  toggleNoteSelected?: (noteId: string) => void
}

export const getNoteClassNames = (
  editing: boolean,
  visible: boolean,
  disableTransition: boolean,
  locked: boolean,
  selected: boolean
) => {
  let cl = 'note'
  if (editing) {
    cl += ' note_editing'
  }

  if (visible) {
    cl += ' note_visible'
  }

  if (disableTransition) {
    cl += ' note_disable-transition'
  }

  if (locked) {
    cl += ' note_locked'
  }

  if (selected) {
    cl += ' note_selected'
  }

  return cl
}
