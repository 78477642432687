import React from 'react'
import './ButtonGeneric.scss'
import { getClassNames } from './util'

interface ButtonProps {
  text: string
  type?: 'delete' | 'cancel' | 'secondary' | ''
  htmlButtonType?: 'button' | 'submit'
  onPress: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const ButtonGeneric: React.FC<ButtonProps> = ({
  text,
  onPress,
  type,
  htmlButtonType,
  disabled,
}) => {
  return (
    <button
      type={htmlButtonType}
      className={`button-generic${getClassNames(type, disabled)}`}
      onClick={disabled ? () => null : onPress}
    >
      {text}
    </button>
  )
}

ButtonGeneric.defaultProps = {
  htmlButtonType: 'button',
}

export default ButtonGeneric
