import { Analytics } from '../services/analytics'
import { defaultAppTitle } from '../util/config'

export const toggleAnimatedBoardResize = () => {
  document.body.classList.add('enable-wall-transitions')

  setTimeout(() => {
    document.body.classList.remove('enable-wall-transitions')
  }, 1000)
}

export const updateWindowTitle = (title: string | null) => {
  if (title) {
    document.title = title + ' | ' + defaultAppTitle
  } else {
    document.title = defaultAppTitle
  }
}

export const getBoardIdFromLocation = (location: Location): string | null => {
  // const q = new URLSearchParams(location.search)
  // return q.get('board')

  // TODO: Temporary, need to figure out Router config
  // to make useParams() recognize the board path
  let path = location.pathname.split('/')
  if (path[1] === 'board' && path[2]) {
    return path[2]
  }

  return ''
}

export const copyBoardUrl = async () => {
  try {
    await navigator.clipboard.writeText(window.location.href)
  } catch (error) {
    Analytics.logCopyUrl({
      success: false,
      error,
    })

    // toast.error(failureMessage || text.copyTextError)
  }
}
