import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/functions'
import { NewBoardData, BatchUpdateNotesData } from '../util/interfaces'
import { toast } from 'react-toastify'
import { text } from '../util/text'
import { Analytics } from '../services/analytics'
import { logDeleteNotes } from '../components/Toolbar/ClearOrDelete/util'

const useEmulator = false

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

// Do not initialise in tests
firebase.analytics
  .isSupported()
  .then((resp) => {
    if (resp) {
      firebase.analytics()
    }
  })
  .catch(() => {})

const firestore = firebase.firestore()
if (useEmulator) {
  firestore.useEmulator('localhost', 8080)
}

export const Api = {
  createBoard: async (data: NewBoardData) => {
    const user = firebase.auth().currentUser
    const time = firebase.firestore.FieldValue.serverTimestamp()
    const batch = firestore.batch()

    // Create the board and store document ref
    const boardRef = firestore.collection('boards').doc()
    batch.set(boardRef, {
      ...data,
      createdAt: time,
    })

    // Use the ref.id to add the board to user's saved-boards
    if (user) {
      const userSavedBoardsRef = firestore
        .collection('saved-boards')
        .doc(user.uid)

      batch.set(
        userSavedBoardsRef,
        {
          [boardRef.id]: {
            title: data.title,
            owned: true,
            savedAt: time,
          },
        },
        { merge: true }
      )
    }

    await batch.commit()

    return boardRef.id
  },

  deleteBoard: async (boardId: string) => {
    // TODO: Remove the board from user's saved-boards

    try {
      await firestore.collection('boards').doc(boardId).update({
        title: text.boardDeleted,
        columns: [],
        height: 0,
        width: 0,
        deleted: true,
      })

      const deleteFn = firebase.functions().httpsCallable('deleteBoardNotes')
      deleteFn({ boardId })
        .then(() => {
          Analytics.logDeleteBoard({ success: true })
          logDeleteNotes('cloud-function', true)
        })
        .catch((err) => {
          logDeleteNotes('cloud-function', false, { error: err, boardId })
        })
    } catch (error) {
      toast.error(text.deleteBoardError)
      Analytics.logDeleteBoard({
        success: false,
        boardId,
        error,
      })
    }
  },

  recursiveDeleteBoard: (boardId: string) => {
    if (useEmulator) {
      firebase.functions().useEmulator('localhost', 5001)
    }

    const deleteFn = firebase.functions().httpsCallable('recursiveDeleteBoard')

    return deleteFn({ boardId })
  },

  streamBoard: (boardId: string, observer: any) => {
    return firestore.collection('boards').doc(boardId).onSnapshot(observer)
  },

  streamNotes: (boardId: string, observer: any) => {
    return firestore
      .collection('boards')
      .doc(boardId)
      .collection('notes')
      .onSnapshot(observer)
  },

  createNote: async (note: any, boardId: string) => {
    // TODO: If boardId doesn't exist, Firestore creates a board
    // for the new note. Should return an error.

    try {
      const time = firebase.firestore.FieldValue.serverTimestamp()
      let drawerTimestamp = ''
      if (note.drawer) {
        drawerTimestamp = note.drawerTimestamp || time
      }

      await firestore
        .collection('boards')
        .doc(boardId)
        .collection('notes')
        .add({
          ...note,
          createdAt: time,
          drawerTimestamp,
        })
      Analytics.logCreateNote({
        success: true,
      })
    } catch (error) {
      toast.error(text.createNoteError)

      Analytics.logCreateNote({
        success: false,
        error,
      })
    }
  },

  batchCreateNotes: (notes: any[], boardId: string) => {
    const createdAt = firebase.firestore.FieldValue.serverTimestamp()

    notes = notes.map((c: any) => {
      return {
        ...c,
        createdAt,
      }
    })

    const batch = firestore.batch()
    const boardNotes = firestore
      .collection('boards')
      .doc(boardId)
      .collection('notes')

    notes.forEach((note: any) => {
      const docRef = boardNotes.doc() // generates id
      batch.set(docRef, note)
    })

    return batch.commit()
  },

  updateNote: (noteId: string, data: Object, boardId: string) => {
    return firestore
      .collection('boards')
      .doc(boardId)
      .collection('notes')
      .doc(noteId)
      .set(data, { merge: true })
  },

  batchUpdateNotes: (notes: BatchUpdateNotesData, boardId: string) => {
    const batch = firestore.batch()
    const notesCollection = firestore
      .collection('boards')
      .doc(boardId)
      .collection('notes')

    const drawerTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    Object.keys(notes).forEach((id) => {
      const docRef = notesCollection.doc(id)

      let updatedNote = { ...notes[id] }
      if (updatedNote.drawer) {
        updatedNote.drawerTimestamp = drawerTimestamp
      }

      batch.update(docRef, updatedNote)
    })

    return batch.commit()
  },

  deleteNote: async (noteId: string, boardId: string) => {
    try {
      await firestore
        .collection('boards')
        .doc(boardId)
        .collection('notes')
        .doc(noteId)
        .delete()

      Analytics.logDeleteNote({ success: true })
    } catch (error) {
      toast.error(text.deleteNoteError)
      Analytics.logDeleteNote({
        success: false,
        error,
      })
    }
  },

  batchDeleteNotes: async (notes: string[], boardId: string) => {
    const batch = firestore.batch()
    const notesCollection = firestore
      .collection('boards')
      .doc(boardId)
      .collection('notes')

    notes.forEach((id) => {
      const docRef = notesCollection.doc(id)
      batch.delete(docRef)
    })

    try {
      await batch.commit()
    } catch (err) {}
  },

  // Temporary solution
  // Should not delete collections this way
  deleteBoardNotes: async (boardId: string) => {
    const notes = await firestore
      .collection('boards')
      .doc(boardId)
      .collection('notes')
      .where('drawer', '!=', true)
      .get()

    notes.forEach((c) => {
      c.ref.delete()
    })
  },

  deleteDrawerNotes: async (boardId: string) => {
    const notes = await firestore
      .collection('boards')
      .doc(boardId)
      .collection('notes')
      .where('drawer', '==', true)
      .get()

    notes.forEach((c) => {
      c.ref.delete()
    })
  },

  moveNoteToDrawer: (noteId: string, data: Object, boardId: string) => {
    return firestore
      .collection('boards')
      .doc(boardId)
      .collection('notes')
      .doc(noteId)
      .set(
        {
          ...data,
          lockedByUser: '',
          drawerTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
  },

  updateColumns: (boardId: string, columns: string[]) => {
    return firestore.collection('boards').doc(boardId).update({
      columns,
    })
  },

  updateBoardTitle: (boardId: string, title: string) => {
    return firestore.collection('boards').doc(boardId).update({
      title,
    })
  },

  updateBoardDimensions: async (
    boardId: string,
    width: number,
    height: number
  ) => {
    try {
      await firestore.collection('boards').doc(boardId).update({
        width,
        height,
      })
    } catch (e) {
      toast.error(text.updateBoardError)
    }
  },

  // User presence: Firestore + Realtime DB + Cloud Functions
  setupUserPresence: (boardId: string) => {
    if (useEmulator) {
      firebase.database().useEmulator('localhost', 9000)
    }

    const dbTimestamp = firebase.database.ServerValue.TIMESTAMP
    const firestoreTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    const currentUser = firebase.auth().currentUser
    const uid = currentUser ? currentUser.uid : 0

    if (!uid) {
      return null
    }

    const userStatusDatabaseRef = firebase.database().ref('/users/' + uid)
    const userStatusFirestoreRef = firestore.doc('/users/' + uid)

    firebase
      .database()
      .ref('.info/connected')
      .on('value', function (snapshot) {
        if (snapshot.val() === false) {
          userStatusFirestoreRef.set(
            {
              last_changed: firestoreTimestamp,
              [boardId]: '',
            },
            { merge: true }
          )

          return
        }

        userStatusDatabaseRef
          .onDisconnect()
          .update({
            last_changed: dbTimestamp,
            [boardId]: '',
          })
          .then(function () {
            userStatusDatabaseRef.update({
              last_changed: dbTimestamp,
              [boardId]: 'online',
            })

            userStatusFirestoreRef.set(
              {
                last_changed: firestoreTimestamp,
                [boardId]: 'online',
              },
              { merge: true }
            )
          })
      })
  },

  streamBoardUsersOnline: (boardId: string, observer: any) => {
    return firestore
      .collection('users')
      .where(boardId, '==', 'online')
      .onSnapshot(observer)
  },

  updateUserPresence: async (userId: string, data: {}) => {
    // const user = firebase.auth().currentUser

    try {
      await firestore
        .collection('users')
        .doc(userId)
        .update({
          ...data,
        })
    } catch (e) {}
  },

  //
  // Authentication
  // https://firebase.google.com/docs/reference/js/firebase.User
  //

  setupAuthListener: (observer: any) => {
    return firebase.auth().onAuthStateChanged(observer)
  },

  authenticateAnonymously: async () => {
    try {
      await firebase.auth().signInAnonymously()
    } catch (e) {}
  },

  signUp: (email: string, password: string) => {
    const user = firebase.auth().currentUser

    if (user) {
      // Convert anonymous to a permanent account
      if (user.isAnonymous) {
        const credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        )

        return user.linkWithCredential(credential)
      }
    }

    // Regular sign up: just a fallback, user should
    // always be authorised (anonymous or registered)
    return firebase.auth().createUserWithEmailAndPassword(email, password)
  },

  signIn: (email: string, password: string) => {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  },

  sendVerificationEmail: async () => {
    const user = firebase.auth().currentUser

    if (user) {
      try {
        await user
          .sendEmailVerification()
          .then(() => {
            console.log('Verification sent')
          })
          .catch((error) => {
            // An error happened.
            console.log('Verification error', error)
          })
      } catch (error) {}
    }
  },

  reAuthenticate: (email: string, password: string) => {
    const user = firebase.auth().currentUser

    if (user) {
      return user.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(email, password)
      )
    }

    // Regular sign in: just a fallback, user should
    // always be authorised (anonymous or registered)
    return firebase.auth().createUserWithEmailAndPassword(email, password)
  },

  signOut: () => {
    return firebase.auth().signOut()
  },

  resetPassword: (email: string) => {
    return firebase.auth().sendPasswordResetEmail(email)
  },

  deleteUser: () => {
    const user = firebase.auth().currentUser

    if (user) {
      user
        .delete()
        .then(() => {
          toast.info(text.deleteAccountSuccess)
        })

        .catch(() => {
          toast.info(text.deleteAccountError)
        })
    }

    return firebase.auth().signOut()
  },

  updateUserProfile: async (profile: any) => {
    const user = firebase.auth().currentUser

    if (user) {
      try {
        await user.updateProfile({
          ...profile,
        })
      } catch (error) {}
    }
  },

  updateUserEmail: async (email: string) => {
    const user = firebase.auth().currentUser

    if (user) {
      return user.updateEmail(email)
    }
  },

  updateUserPassword: async (password: string) => {
    const user = firebase.auth().currentUser

    if (user) {
      return user.updatePassword(password)
    }
  },

  saveBoard: async ({
    id,
    title,
    owned,
  }: {
    id: string
    title: string
    owned: boolean
  }) => {
    const user = firebase.auth().currentUser

    if (user) {
      return firestore
        .collection('saved-boards')
        .doc(user.uid)
        .set(
          {
            [id]: {
              title,
              owned,
              savedAt: firebase.firestore.FieldValue.serverTimestamp(),
            },
          },
          { merge: true }
        )
    }
  },

  updateSavedBoards: async (boards: any) => {
    const user = firebase.auth().currentUser

    if (user) {
      return firestore.collection('saved-boards').doc(user.uid).set(boards)
    }
  },

  streamUsersBoards: (observer: any) => {
    const user = firebase.auth().currentUser

    try {
      if (user) {
        return firestore
          .collection('saved-boards')
          .doc(user.uid)
          .onSnapshot(observer)
      }
    } catch {}
  },

  sendEmailInvitation: (
    to: string,
    boardTitle: string,
    url: string,
    sender: any
  ) => {
    const sendFn = firebase.functions().httpsCallable('sendEmailInvitation')
    return sendFn({ to, boardTitle, url, sender })
  },
}

// export const setAuthPersistence = () => {
//   if (useEmulator) {
//     firebase.auth().useEmulator('http://localhost:9099/')
//   }

//   return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
// }
