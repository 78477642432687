import { observer } from 'mobx-react-lite'
import React from 'react'
import { Modal } from 'react-responsive-modal'
import { useRootStore } from '../../../store/RootStateProvider'
import ResetPassword from './ResetPassword'

interface Props {
  initialEmail?: string
}

const ResetPasswordModal: React.FC<Props> = observer(({ initialEmail }) => {
  const { uiStore } = useRootStore().rootStore

  return (
    <Modal
      open={uiStore.secondaryModalOpen === 'reset-password'}
      classNames={{ modal: 'modal-secondary' }}
      onClose={uiStore.closeSecondaryModal}
      center={true}
    >
      <ResetPassword initialEmail={initialEmail} />
    </Modal>
  )
})

export default ResetPasswordModal
