import React from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../../store/RootStateProvider'
import './ProfileBoards.scss'
import { Link } from 'react-router-dom'
import { text } from '../../../util/text'
import { ReactComponent as SavedIcon } from '../../AppHeader/SavedBoardToggle/assets/ico_saved-board-toggle.svg'
import ReactTooltip from 'react-tooltip'

interface Props {
  id: string
  title: string
  removeSavedBoard: (id: string) => void
}

const SavedBoardListItem: React.FC<Props> = observer(
  ({ id, title, removeSavedBoard }) => {
    const { uiStore } = useRootStore().rootStore

    return (
      <li>
        <Link to={`/board/${id}`} onClick={uiStore.closeModal}>
          {title}
        </Link>

        <button
          type="button"
          onClick={() => removeSavedBoard(id)}
          className="saved-boards__button-remove"
          aria-label={text.removeSavedBoard}
          data-tip={text.removeSavedBoard}
          data-for={`profile-boards-tooltip-${id}`}
        >
          <SavedIcon />

          {/* 
            Render tooltip here because it must be destroyed with the button.
            Would stay on screen after delete otherwise (Chrome & Firefox).
          */}
          <ReactTooltip
            id={`profile-boards-tooltip-${id}`}
            place="left"
            effect="solid"
            delayShow={500}
          />
        </button>
      </li>
    )
  }
)

export default SavedBoardListItem
