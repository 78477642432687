import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../../store/RootStateProvider'
import { text } from '../../../util/text'
import ButtonGeneric from '../../Buttons/ButtonGeneric/ButtonGeneric'
import PasswordInput from '../../Forms/PasswordInput/PasswordInput'
import { Api } from '../../../services/api'
import { useHistory } from 'react-router'
import ModalTitle from '../../ModalTitle/ModalTitle'

const ProfileDelete: React.FC<{}> = observer(() => {
  const history = useHistory()
  const { sessionStore, uiStore } = useRootStore().rootStore
  const user = sessionStore.currentUser

  const [password, setPassword] = useState('')
  const [invalidPassword, setInvalidPassword] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [busy, setBusy] = useState(false)

  const onChangePassword = (e: any) => {
    setErrorMsg('')
    setInvalidPassword(false)
    setPassword(e.target.value)
  }

  const handleError = (error: { code: string; message: string }) => {
    setErrorMsg(error.message)

    switch (error.code) {
      case 'auth/wrong-password':
        setInvalidPassword(true)
        break

      default:
        break
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    if (busy) return
    setErrorMsg('')

    if (!password) {
      setInvalidPassword(true)
      return
    }

    setBusy(true)

    Api.reAuthenticate(user.email, password)
      .then(() => {
        Api.deleteUser()
          .then(() => {
            setBusy(false)
            uiStore.closeSecondaryModal()
            uiStore.closeModal()

            sessionStore.setCurrentUser(null)

            history.replace('/')

            localStorage.setItem('repeatRegisteredVisit', '')
            sessionStore.setRepeatRegisteredVisit(false)
          })
          .catch((error) => {
            handleError(error)
            setBusy(false)
          })
      })
      .catch((error) => {
        handleError(error)
        setBusy(false)
      })
  }

  return (
    <section>
      <ModalTitle text={text.deleteAccount} />

      <form onSubmit={onSubmit}>
        <p className="text-generic bottom-spacing-medium">
          {text.deleteAccountConfirm}
        </p>

        <label htmlFor="profile-delete-password">{text.password}</label>
        <PasswordInput
          id="profile-delete-password"
          password={password}
          onChange={onChangePassword}
          invalid={invalidPassword}
          disabled={busy}
        />

        {errorMsg ? <p className="form-error-msg">{errorMsg}</p> : null}

        <div className="modal-buttons__stack">
          <ButtonGeneric
            htmlButtonType="submit"
            text={text.deleteAccount}
            onPress={onSubmit}
            disabled={busy}
          />
        </div>
      </form>
    </section>
  )
})

export default ProfileDelete
