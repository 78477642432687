import React from 'react'
import { ReactComponent as IconRemove } from './assets/icon_remove-dark.svg'
import './ButtonRemove.scss'

interface ButtonRemoveComponent {
  color?: string
  label: string
  onClick?: (e?: any) => void
}

const ButtonRemove: React.FC<ButtonRemoveComponent> = ({
  color,
  label,
  onClick,
}) => {
  return (
    <button
      className="button-remove"
      onClick={onClick ? (e) => onClick(e) : undefined}
      aria-label={label}
      type="button"
    >
      <IconRemove fill={color} />
      <div className="button-remove__inner" />
    </button>
  )
}

ButtonRemove.defaultProps = {
  color: '#333',
}

export default ButtonRemove
