// Simplified this: https://github.com/dejorrit/scroll-data-hook

import * as React from 'react'
import { ScrollData, OptionsType } from './scrollTypes'

const SCROLL_END_DURATION = 100

const INITIAL_DATA = {
  scrolling: false,
  direction: {
    x: null,
    y: null,
  },
  speed: {
    x: 0,
    y: 0,
  },
  position: {
    x: 0,
    y: 0,
  },
}

function getPositionX() {
  return window.pageXOffset || 0
}

function getPositionY() {
  return window.pageYOffset || 0
}

function getDirectionX(x: number, frameValues: ScrollData): string | null {
  if (x > frameValues.position.x) return 'right'
  if (x < frameValues.position.x) return 'left'
  return null
}

function getDirectionY(y: number, frameValues: ScrollData): string | null {
  if (y > frameValues.position.y) return 'down'
  if (y < frameValues.position.y) return 'up'
  return null
}

export const useScroll = (options: OptionsType = {}): ScrollData => {
  const [data, setData] = React.useState<ScrollData>(INITIAL_DATA)
  const startValues = React.useRef<ScrollData>(INITIAL_DATA)
  const frameValues = React.useRef<ScrollData>(INITIAL_DATA)
  const startTimestamp = React.useRef<number | null>()
  const frameTimestamp = React.useRef<number | null>()
  const scrollTimeout = React.useRef<any>(null)
  const raf = React.useRef<any>(null)

  function frame(timestamp: number) {
    if (!startTimestamp.current) startTimestamp.current = timestamp

    // Set new position values
    const position = {
      x: getPositionX(),
      y: getPositionY(),
    }

    // Set new direction values
    const direction = {
      x: getDirectionX(position.x, frameValues.current),
      y: getDirectionY(position.y, frameValues.current),
    }

    // Set new speed values
    const timestampDiff = timestamp - (frameTimestamp.current || 0)
    const speed = {
      x:
        (Math.abs(frameValues.current.position.x - position.x) /
          Math.max(1, timestampDiff)) *
        1000,
      y:
        (Math.abs(frameValues.current.position.y - position.y) /
          Math.max(1, timestampDiff)) *
        1000,
    }

    const nextframeValues = {
      ...frameValues.current,
      scrolling: true,
      direction,
      speed,
      position,
    }

    // Store new values
    frameValues.current = nextframeValues

    // Update the state
    setData(nextframeValues)

    // Set frameTimestamp for speed calculation
    frameTimestamp.current = timestamp

    // We're still scrolling, so call tick method again
    raf.current = requestAnimationFrame(frame)
  }

  function clearAndSetscrollTimeout() {
    if (scrollTimeout.current) clearTimeout(scrollTimeout.current)
    scrollTimeout.current = setTimeout(scrollEnd, SCROLL_END_DURATION)
  }

  function onScroll() {
    if (!frameValues.current.scrolling) {
      scrollStart()
    }

    clearAndSetscrollTimeout()
  }

  function scrollStart() {
    // Save data at the moment of starting so we have
    // something to compare the current values against
    startValues.current = { ...frameValues.current }

    // Start RAF
    raf.current = requestAnimationFrame(frame)

    // If present, call onScrollStart function
    if (typeof options.onScrollStart === 'function') {
      options.onScrollStart()
    }
  }

  function scrollEnd() {
    // Reset scroll data
    frameValues.current = {
      ...frameValues.current,
      scrolling: false,
      direction: {
        x: null,
        y: null,
      },
      speed: {
        x: 0,
        y: 0,
      },
    }

    // Update the state
    setData(frameValues.current)

    // Cancel RAF
    cancelAnimationFrame(raf.current)
    startTimestamp.current = null
    frameTimestamp.current = null

    // If present, call onScrollEnd function
    if (typeof options.onScrollEnd === 'function') {
      options.onScrollEnd()
    }
  }

  React.useEffect(() => {
    // Add scrollListener
    window.addEventListener('scroll', onScroll, true)

    // Remove listener when unmounting
    return () => {
      clearTimeout(scrollTimeout.current)
      window.removeEventListener('scroll', onScroll, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Return data with rounded values
  return {
    ...data,
    speed: {
      x: Math.round(data.speed.x),
      y: Math.round(data.speed.y),
    },
    position: {
      x: Math.round(data.position.x),
      y: Math.round(data.position.y),
    },
  }
}
