import React from 'react'
import { observer } from 'mobx-react-lite'
import { Api } from '../../services/api'
import { useRootStore } from '../../store/RootStateProvider'
import { text } from '../../util/text'
import '../Auth/assets/Auth.scss'
import './Profile.scss'
import { useHistory } from 'react-router-dom'
import ButtonGeneric from '../Buttons/ButtonGeneric/ButtonGeneric'
import ProfileNameModal from './ProfileName/ProfileNameModal'
import ProfileEmailModal from './ProfileEmail/ProfileEmailModal'
import ProfileDeleteModal from './ProfileDelete/ProfileDeleteModal'
import ProfilePasswordModal from './ProfilePassword/ProfilePasswordModal'

const Profile: React.FC<{}> = observer(() => {
  const history = useHistory()

  const { boardStore, sessionStore, uiStore, savedBoardsStore } =
    useRootStore().rootStore

  const onPressSignOut = () => {
    const { currentUser } = sessionStore

    if (currentUser && currentUser.id) {
      if (boardStore.boardId) {
        Api.updateUserPresence(currentUser.id, {
          [boardStore.boardId]: '',
        }).then(() => {
          uiStore.closeModal()
          history.replace('/')
          Api.signOut()
        })
      } else {
        uiStore.closeModal()
        Api.signOut()
      }
    }

    savedBoardsStore.setBoards([])
  }

  // const resetPassword = () => {
  // Api.resetPassword(email)
  // }

  // const setError = (error: any) => {
  //   if (error && error.message) {
  //     setErrorMsg(error.message)
  //   } else {
  //     setErrorMsg('Something went wrong, sorry. Please try again.')
  //   }
  // }

  return (
    <section className="profile">
      <h2 className="hide">{text.yourProfile}</h2>
      <p className="text-generic profile__row">
        <span>
          {text.name}:{' '}
          <b>{sessionStore.currentUser.displayName || text.anonymous}</b>
        </span>

        <button
          type="button"
          className="link"
          onClick={() => uiStore.setSecondaryModalOpen('profile-name')}
        >
          {text.changeName}
        </button>
      </p>

      <p className="text-generic profile__row">
        <span>
          {text.email}: <b>{sessionStore.currentUser.email}</b>
        </span>

        <button
          type="button"
          className="link"
          onClick={() => uiStore.setSecondaryModalOpen('profile-email')}
        >
          {text.changeEmail}
        </button>
      </p>

      <p className="text-generic profile__row">
        <span>{text.password}</span>

        <button
          type="button"
          className="link"
          onClick={() => uiStore.setSecondaryModalOpen('profile-password')}
        >
          {text.changePassword}
        </button>
      </p>

      <p className="text-generic profile__row">
        <span>{text.account}</span>

        <button
          type="button"
          className="link"
          onClick={() => uiStore.setSecondaryModalOpen('profile-delete')}
        >
          {text.deleteAccount}
        </button>
      </p>

      <div className="modal-buttons_centered form-separator form-separator_medium">
        <ButtonGeneric
          type="secondary"
          onPress={onPressSignOut}
          text={text.signOut}
        />
      </div>

      <ProfileNameModal />
      <ProfileEmailModal />
      <ProfileDeleteModal />
      <ProfilePasswordModal />
    </section>
  )
})

export default Profile
