import React, { useEffect, useState } from 'react'
import { Api } from '../../services/api'
import { useHistory } from 'react-router-dom'
import ButtonGeneric from '../Buttons/ButtonGeneric/ButtonGeneric'
import { stripHtml, text } from '../../util/text'
import { getNewBoardDimensions } from '../../util/dimensions'
import { Analytics } from '../../services/analytics'
import { toast } from 'react-toastify'
import { useRootStore } from '../../store/RootStateProvider'
import { observer } from 'mobx-react-lite'
import './assets/CreateBoard.scss'

// const demoBoardPath = `/board/${process.env.REACT_APP_DEMO_BOARD_ID}`

const CreateBoard: React.FC<{}> = observer(() => {
  const authEnabled = process.env.REACT_APP_AUTH_ENABLED === 'true'

  const history = useHistory()
  const { sessionStore, uiStore } = useRootStore().rootStore
  const user = sessionStore.currentUser

  const [title, setTitle] = useState('')
  // const [invalid, setInvalid] = useState(false)

  const focusInput = () => {
    const titleInput = document.getElementById('board-title')
    if (titleInput) {
      titleInput.focus()
    }
  }

  const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const t = event.target.value
    setTitle(t)

    // if (t) {
    //   setInvalid(false)
    // }
  }

  const onSubmit = (
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()

    if (title) {
      // setInvalid(false)
      createBoard()
    } else {
      // setInvalid(true)
      focusInput()
    }
  }

  const createBoard = () => {
    const boardDimensions = getNewBoardDimensions()

    Api.createBoard({
      title: stripHtml(title).trim(),
      owner: { id: user.id },
      ...boardDimensions,
      columns: [],
      access: 'public',
      // users: [sessionStore.currentUser.id],
    })
      .then((id) => {
        history.push(`/board/${id}`)

        Analytics.logCreateBoard({
          success: true,
          boardId: id,
        })
      })
      .catch((error) => {
        toast.error(text.createBoardError)
        Analytics.logCreateBoard({ success: false, error })
      })
  }

  const onClickSignIn = (e: any) => {
    e.preventDefault()
    uiStore.setAuthModalOpen(true)
  }

  useEffect(() => {
    focusInput()
  }, [])

  return (
    <div className="create-board">
      <form onSubmit={onSubmit}>
        <label htmlFor="board-title">{text.boardTitle}</label>
        <div className={`create-board__input`}>
          <input
            id="board-title"
            type="text"
            value={title}
            onChange={onChangeTitle}
          />
        </div>

        <ButtonGeneric onPress={onSubmit} text={text.createBoard} />

        {authEnabled && (
          <div className="form-separator">
            {sessionStore.currentUser &&
            !sessionStore.currentUser.isAnonymous ? null : (
              <ButtonGeneric
                onPress={onClickSignIn}
                text={
                  sessionStore.repeatRegisteredVisit ? text.signIn : text.signUp
                }
                htmlButtonType="button"
                type="secondary"
              />
            )}
          </div>
        )}

        {/* Why this can't be focused with a Tab? Ignores tabIndex too. */}
        {/* 
              <p className="text-generic">
                <Link to={demoBoardPath}>{text.tryDemoBoard}</Link>
              </p>
          */}

        <p className="text-generic reset-margin">
          <button
            className="link"
            onClick={() =>
              history.push(`/board/${process.env.REACT_APP_DEMO_BOARD_ID}`)
            }
            type="button"
          >
            {text.tryDemoBoard}
          </button>
        </p>
      </form>
    </div>
  )
})

export default CreateBoard
