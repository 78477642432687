import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../store/RootStateProvider'
import { text } from '../../util/text'
import Tab from '../Tabs/Tab'
import '../Tabs/Tabs.scss'
import Auth from './Auth'

const SignInOrSignUp: React.FC<{}> = observer(() => {
  const { sessionStore } = useRootStore().rootStore

  const [signInEnabled, setSignInEnabled] = useState(
    sessionStore.repeatRegisteredVisit
  )

  return (
    <>
      <div className="tabs">
        <Tab
          active={signInEnabled}
          text={text.signIn}
          onPress={() => setSignInEnabled(true)}
        />
        <Tab
          active={!signInEnabled}
          text={text.signUp}
          onPress={() => setSignInEnabled(false)}
        />
      </div>

      <Auth signInEnabled={signInEnabled} />
    </>
  )
})

export default SignInOrSignUp
