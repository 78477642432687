import find from 'lodash/find'
import { observer } from 'mobx-react-lite'
import React from 'react'
import ContentEditable from 'react-contenteditable'
import { useRootStore } from '../../../store/RootStateProvider'
import { getDraggableNoteStyles } from '../../Note/util'
import SelectionToolbar from './SelectionToolbar/SelectionToolbar'
import { Box } from 'react-drag-to-select'

interface Props {
  width: number
  height: number
  selectedNotes: any[]
  selectionBox: Box
}

const SelectionDragPreview: React.FC<Props> = observer(
  ({ width, height, selectedNotes, selectionBox }) => {
    const { notesStore } = useRootStore().rootStore

    const notes: any[] = []
    selectedNotes.forEach((id: string) => {
      const n = find(notesStore.notes, { id })

      if (n) {
        notes.push({
          ...n,
          left: n.left - selectionBox.left,
          top: n.top - selectionBox.top,
        })
      }
    })

    return (
      <div
        className="selection"
        style={{
          width,
          height,
        }}
      >
        <SelectionToolbar />

        {notes.map((n) => (
          <div
            key={`preview-${n.id}`}
            className="note note_visible note_disable-transition note_selected"
            style={getDraggableNoteStyles(n.left, n.top, false, n.zIndex)}
          >
            <div className="note_editable" style={{ backgroundColor: n.color }}>
              <div className="note_editable__inner">
                <ContentEditable
                  className="note_editable__text"
                  disabled={true}
                  html={n.text}
                  onChange={() => null}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
)

export default SelectionDragPreview
