import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { BrowserRouter, Route } from 'react-router-dom'
import CookiesNotification from './components/CookiesNotification/CookiesNotification'
import { ThemeProvider } from './components/Theme/ThemeProvider'
import ToastRenderer from './components/Toastify/ToastRenderer'
import Home from './components/Home/Home'
import { RootStateProvider } from './store/RootStateProvider'
import { isTouchScreen } from './util/touch'

const App = () => {
  return (
    <BrowserRouter>
      <RootStateProvider>
        <ThemeProvider>
          <DndProvider backend={isTouchScreen() ? TouchBackend : HTML5Backend}>
            <Route path="/" component={Home} />
            {/* <Route path={['/', '/board/:id']} component={Home} /> */}
          </DndProvider>

          <CookiesNotification />
          <ToastRenderer />
        </ThemeProvider>
      </RootStateProvider>
    </BrowserRouter>
  )
}

export default App
