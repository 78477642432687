export const minColumnWidth = 147

// Can add a column only if there's enough space on the board
export const canAddColumn = (): boolean => {
  const columnsContainer: HTMLElement | null = document.querySelector(
    '.columns'
  )
  const count = document.querySelectorAll('.column').length

  if (columnsContainer) {
    if (Math.floor(columnsContainer.offsetWidth / minColumnWidth) > count) {
      return true
    } else {
      return false
    }
  }

  return true
}
