import { observer } from 'mobx-react-lite'
import React from 'react'
import { Modal } from 'react-responsive-modal'
import { useRootStore } from '../../../store/RootStateProvider'
import ProfileName from './ProfileName'

const ProfileNameModal: React.FC<{}> = observer(() => {
  const { uiStore } = useRootStore().rootStore

  return (
    <Modal
      open={uiStore.secondaryModalOpen === 'profile-name'}
      classNames={{ modal: 'modal-secondary' }}
      onClose={uiStore.closeSecondaryModal}
      center={true}
    >
      <ProfileName saveCallback={uiStore.closeSecondaryModal} />
    </Modal>
  )
})

export default ProfileNameModal
