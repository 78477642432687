import firebase from 'firebase/app'

export const canLogEvens = true // don't log on local?

interface LogData {
  boardId?: string
  success?: boolean
  error?: any

  columns?: string[]
  update_type?: string
}

export const Analytics = {
  log: (eventName: string, data?: LogData) => {
    if (canLogEvens) {
      firebase.analytics().logEvent(eventName, data)
    }
  },

  setUserId: (id: string) => {
    firebase.analytics().setUserId(id)
  },

  setUserProperties: (props: Object) => {},

  logUserAuth: (data?: any) => {
    Analytics.log('user_auth', data)
  },

  logCreateBoard: (data?: LogData) => {
    Analytics.log('create_board', data)
  },

  logDeleteBoard: (data?: any) => {
    Analytics.log('delete_board', data)
  },

  logStreamBoard: (data?: LogData) => {
    Analytics.log('stream_board', data)
  },

  logStreamBoardUsers: (data?: LogData) => {
    Analytics.log('stream_board_users', data)
  },

  logUpdateBoardTitle: (data?: LogData) => {
    Analytics.log('update_board_title', data)
  },

  logCreateNote: (data?: LogData) => {
    Analytics.log('create_note', data)
  },

  logMoveNote: (data?: LogData) => {
    Analytics.log('move_note', data)
  },

  logDeleteNote: (data?: LogData) => {
    Analytics.log('delete_note', data)
  },

  logDeleteNotes: (data?: any) => {
    Analytics.log('delete_notes', data)
  },

  logCreateColumn: (data?: LogData) => {
    Analytics.log('create_column', data)
  },

  logUpdateColumns: (data?: LogData) => {
    Analytics.log('update_columns', data)
  },

  logUpdateUsername: (data?: LogData) => {
    Analytics.log('update_username', data)
  },

  logUpdateEmail: (data?: LogData) => {
    Analytics.log('update_email', data)
  },

  logOpenDemoBoard: () => {
    Analytics.log('open_demo_board')
  },

  logImportNotes: (data?: any) => {
    Analytics.log('import_notes', data)
  },

  logCopyUrl: (data?: any) => {
    Analytics.log('copy_url', data)
  },

  logProFeatureUsed: (data?: any) => {
    Analytics.log('pro_feature', data)
  },

  logInviteEmail: (data?: any) => {
    Analytics.log('invite_email', data)
  },
}
