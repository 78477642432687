import React from 'react'
import './Selection.scss'
import { useRootStore } from '../../../store/RootStateProvider'
import SelectionToolbar from './SelectionToolbar/SelectionToolbar'
import { observer } from 'mobx-react-lite'

interface SelectionProps {
  isDraggingSelection: boolean
  drag: any
  deleteSelectedNotes: () => void
  moveSelectedNotesToDrawer: () => void
  clearSelection: () => void

  notesList: React.ReactNode
}

const Selection: React.FC<SelectionProps> = observer(
  ({
    isDraggingSelection,
    drag,
    deleteSelectedNotes,
    moveSelectedNotesToDrawer,
    clearSelection,

    notesList,
  }) => {
    const { boardStore, selectionStore, notesStore } = useRootStore().rootStore
    const { selecting, selectionBox } = selectionStore

    let containerStyle: any = { display: 'none' }
    let innerStyle: any = { display: 'none' }

    if (selectionBox && !selecting && notesStore.boardNotesSelectedCount > 0) {
      containerStyle = {
        opacity: isDraggingSelection ? 0 : 1,
        // height: isDraggingSelection ? 0 : '',
        width: selectionBox.width,
        height: selectionBox.height,
        left: selectionBox.left,
        top: selectionBox.top,
      }

      if (boardStore.board) {
        innerStyle = {
          left: -selectionBox.left,
          top: -selectionBox.top,
          width: boardStore.board.width - 120, // - toolbar & padding
          height: boardStore.board.height - 120, // - header & padding
        }
      }
    }

    return (
      <div
        className="selection"
        ref={drag}
        style={containerStyle}
        data-draggable={true}
      >
        <div
          id="selection-board"
          className="selection__inner"
          style={innerStyle}
          data-draggable={true}
        >
          {notesList}
        </div>

        {!selecting &&
          selectionBox &&
          notesStore.boardNotesSelectedCount > 0 && (
            <SelectionToolbar
              deleteSelectedNotes={deleteSelectedNotes}
              moveSelectedNotesToDrawer={moveSelectedNotesToDrawer}
              clearSelection={clearSelection}
            />
          )}
      </div>
    )
  }
)

export default Selection
