import findIndex from 'lodash/findIndex'
import { observable, action, makeObservable, computed } from 'mobx'
import sortBy from 'lodash/sortBy'

export class NotesStore {
  notes: any = []
  setNotes = (notes: any | null) => {
    this.notes = notes

    this.setNextZindex()
  }

  get boardNotes() {
    return this.notes.filter((note: any) => !note.drawer)
  }

  get boardNotesCount() {
    return this.boardNotes.length
  }

  get boardNotesSelected() {
    return this.boardNotes.filter(
      (note: any) => this.notesIdsSelected.indexOf(note.id) > -1
    )
  }

  get boardNotesSelectedCount() {
    return this.boardNotesSelected.length
  }

  get boardNotesNotSelected() {
    return this.boardNotes.filter(
      (note: any) => this.notesIdsSelected.indexOf(note.id) === -1
    )
  }

  notesIdsToSelect: string[] = []
  setNotesIdsToSelect = (ids: string[]) => {
    this.notesIdsToSelect = ids
  }

  notesIdsSelected: string[] = []
  setNotesIdsSelected = (ids: string[]) => {
    this.notesIdsSelected = ids
  }

  deselectNote = (id: string) => {
    const updatedSelected = [...this.notesIdsSelected]
    updatedSelected.splice(this.notesIdsSelected.indexOf(id), 1)

    this.setNotesIdsSelected(updatedSelected)
  }

  get drawerNotes() {
    let notes = this.notes.filter((note: any) => note.drawer)
    notes = sortBy(notes, 'drawerTimestamp')
    return notes
  }

  get drawerNotesCount() {
    return this.drawerNotes.length
  }

  updateNote = (note: any) => {
    const index = findIndex(this.notes, { id: note.id })

    if (index > -1) {
      this.notes[index] = {
        ...this.notes[index],
        ...note,
      }
    }
  }

  nextZindex: number = 1
  setNextZindex = () => {
    let next = 1

    if (this.notes.length) {
      const notesIndexes = this.notes.map((c: any) => c.zIndex || 1)
      next = Math.max.apply({}, notesIndexes)
      next += 1
    } else {
      next = 1
    }

    this.nextZindex = next
  }

  constructor() {
    makeObservable(this, {
      notes: observable,
      setNotes: action,

      boardNotes: computed,
      boardNotesCount: computed,
      boardNotesSelected: computed,
      boardNotesSelectedCount: computed,
      boardNotesNotSelected: computed,

      drawerNotes: computed,
      drawerNotesCount: computed,

      notesIdsToSelect: observable,
      setNotesIdsToSelect: action,
      notesIdsSelected: observable,
      setNotesIdsSelected: action,
      deselectNote: action,

      updateNote: action,
      nextZindex: observable,
      setNextZindex: action,
    })
  }
}
