import find from 'lodash/find'
import React, { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import useHotKeys from '@reecelucas/react-use-hotkeys'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { DnDItemTypes } from '../DnD/DnDItemTypes'
import BoardDragLayer from '../DnD/BoardDragLayer'
import {
  getNoteCoordsOnDrop,
  getAdjustedSelectionBox,
  getSelectionDropDiff,
} from '../DnD/util'
import { Api } from '../../services/api'
import {
  getNewNotePosition,
  showDemoBoardNotification,
  showTouchSupportNotification,
} from './util'
import { Analytics } from '../../services/analytics'
import Columns from '../Columns/Columns'
import EmptyBoardTip from './EmptyBoardTip/EmptyBoardTip'
import {
  BatchUpdateNotesData,
  BoardInterface,
  DraggableNoteItem,
} from '../../util/interfaces'
import { text } from '../../util/text'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../store/RootStateProvider'
import NotesList from './NotesList'
import './assets/Board.scss'
import './Selection/Selection.scss'

import MouseSelection from './Selection/MouseSelection'
import { defaultBoardDimensions, noteSize } from '../../util/dimensions'
import Selection from './Selection/Selection'

interface BoardProps {
  board: BoardInterface | null
  boardId: string
  userId: string
}

const Board: React.FC<BoardProps> = observer(({ board, boardId }) => {
  const {
    colorPickerStore,
    sessionStore,
    notesStore,
    uiStore,
    usersStore,
    selectionStore,
  } = useRootStore().rootStore

  const { selectionBox } = selectionStore
  const currentUser = sessionStore.currentUser?.id || ''

  const moveNote = (id: string, left: number, top: number) => {
    const movedNote = {
      drawer: false,
      left,
      top,
      zIndex: notesStore.nextZindex,
      lockedByUser: '', // Release lock created in Note.tsx
    }

    // Update dragged note's position immediately so it
    // doesn't need to wait for Firebase response, causing
    // the note jump back to original position for a moment.
    notesStore.updateNote({ ...movedNote, id })

    Api.updateNote(id, movedNote, boardId)
      .then(() => {})
      .catch((error) => {
        toast.error(text.updateNoteError)
        Analytics.logMoveNote({
          success: false,
          error,
        })
      })
  }

  const toggleNoteSelected = (noteId: string) => {
    const selectedIndex = notesStore.notesIdsSelected.indexOf(noteId)

    if (selectedIndex === -1) {
      // Select
      const noteInStore = find(notesStore.boardNotes, { id: noteId })

      if (noteInStore) {
        // Adjust selection box
        selectionStore.setSelectionBox({
          top: noteInStore.top - 20,
          left: noteInStore.left - 20,
          width: noteSize,
          height: noteSize,
        })
      }

      notesStore.setNotesIdsSelected([...notesStore.notesIdsSelected, noteId])
    } else {
      // Deselect
      notesStore.deselectNote(noteId)
    }
  }

  const handleDrop = (item: DraggableNoteItem, monitor: any) => {
    if (item.type === DnDItemTypes.NOTE) {
      let { left, top } = getNoteCoordsOnDrop(monitor)
      moveNote(item.id, left, top)
    } else if (item.type === DnDItemTypes.SELECTION) {
      const diff = getSelectionDropDiff(monitor, selectionBox, {
        width: board?.width || defaultBoardDimensions.width,
        height: board?.height || defaultBoardDimensions.height,
      })
      moveSelectedNotes(diff)
    }
  }

  const deleteSelectedNotes = () => {
    notesStore.notesIdsSelected.forEach((id) => {
      const noteInStore = find(notesStore.boardNotes, { id })

      if (noteInStore) {
        sessionStore.saveRecentAction({
          type: 'delete-note',
          data: noteInStore,
          options: {
            boardId: boardId,
          },
        })
      }
    })

    Api.batchDeleteNotes(notesStore.notesIdsSelected, boardId)

    clearSelection()
  }

  const moveSelectedNotes = (diff: { x: number; y: number }) => {
    const data: BatchUpdateNotesData = {}

    notesStore.notesIdsSelected.forEach((id) => {
      const noteInStore = find(notesStore.boardNotes, { id })

      if (noteInStore) {
        data[id] = {
          drawer: false,
          left: noteInStore.left + diff.x,
          top: noteInStore.top + diff.y,
          lockedByUser: '',
        }

        // Update immediately in the store
        notesStore.updateNote({ ...data[id], id })
      }
    })

    Api.batchUpdateNotes(data, boardId)
  }

  const moveSelectedNotesToDrawer = () => {
    const data: BatchUpdateNotesData = {}

    notesStore.notesIdsSelected.forEach((id) => {
      // Check if the note is still on the board before moving:
      // could be deleted by someone else in the mean time,
      // which would cause the batch update to fail.
      if (find(notesStore.boardNotes, { id })) {
        data[id] = {
          drawer: true,
        }
      }
    })

    clearSelection()
    Api.batchUpdateNotes(data, boardId)
  }

  const clearSelection = useCallback(() => {
    selectionStore.setSelectionBox(null)
    notesStore.setNotesIdsSelected([])
  }, [selectionStore, notesStore])

  useHotKeys('Escape', () => {
    if (selectionBox && notesStore.notesIdsSelected.length > 0) {
      clearSelection()
    }
  })

  useHotKeys('.+a', () => {
    notesStore.setNotesIdsSelected(
      notesStore.boardNotes.map((n: Pick<DraggableNoteItem, 'id'>) => n.id)
    )
  })

  const [, drop] = useDrop({
    accept: [DnDItemTypes.NOTE, DnDItemTypes.SELECTION],
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const lockSelectedNotes = () => {
    const data: BatchUpdateNotesData = {}

    notesStore.notesIdsSelected.forEach((id) => {
      const noteInStore = find(notesStore.boardNotes, { id })

      if (noteInStore) {
        data[id] = {
          lockedByUser: currentUser,
        }

        // Update immediately in the store
        // TODO: need a batchUpdateNotes method in store
        notesStore.updateNote({ ...data[id], id })
      }
    })

    Api.batchUpdateNotes(data, boardId)
  }

  // useDrag Return Value Array:
  // [0] collected props,
  // [1] connector function for the drag source
  // [2] connector function for the drag preview
  const [{ isDraggingSelection }, selectionDrag, preview] = useDrag({
    item: {
      type: DnDItemTypes.SELECTION,
      width: selectionBox?.width || 0,
      height: selectionBox?.height || 0,
      selectedNotes: notesStore.notesIdsSelected,
      selectionBox,
    },
    begin: () => {
      // setDisableTransition(true)
      lockSelectedNotes()
    },
    end: () => {
      setTimeout(() => {
        // if (isMounted) {
        // setDisableTransition(false)
        // }
      }, 500)
    },
    collect: (monitor) => ({
      isDraggingSelection: monitor.isDragging(),
    }),
  })

  const onMouseUp = (e: any) => {
    if (
      e.target.className === 'board' ||
      e.target.className === 'column' ||
      e.target.className === 'selection__inner'
    ) {
      clearSelection()
    }
  }

  const onDoubleClickBoard = (e: any) => {
    const cl = e.target.classList

    if (
      cl.contains('board') ||
      cl.contains('column') ||
      cl.contains('columns')
    ) {
      e.preventDefault()
      createNote(e)
    }
  }

  const createNote = (e: React.MouseEvent<HTMLDivElement>) => {
    if (boardId && sessionStore.currentUser) {
      let position = getNewNotePosition(e)

      Api.createNote(
        {
          text: '',
          color:
            colorPickerStore.randomColor1 || colorPickerStore.selectedColor,
          ...position,
          drawer: false,
          zIndex: notesStore.nextZindex,
        },
        boardId
      )

      colorPickerStore.getNewRandomColors()
    }
  }

  useEffect(() => {
    toast.dismiss()

    showTouchSupportNotification()
    showDemoBoardNotification()
  }, [])

  useEffect(() => {
    const unsubscribe = Api.streamNotes(boardId, {
      next: (snapshot: any) => {
        const updatedNotes = snapshot.docs.map((docSnapshot: any) => {
          const data = docSnapshot.data()

          return {
            ...data,
            id: docSnapshot.id,
          }
        })

        notesStore.setNotes(updatedNotes)
      },
      error: () => {
        toast.error(text.loadBoardNotesError)
      },
    })

    return () => {
      unsubscribe()
      notesStore.setNotes([])
      usersStore.setUsers([])
      uiStore.closeModal()
    }
  }, [boardId, notesStore, uiStore, usersStore])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  useEffect(() => {
    // Finished selection: adjust selection box
    if (notesStore.notesIdsSelected.length > 0 && !selectionStore.selecting) {
      let notesPositions: any = []

      notesStore.notesIdsSelected.forEach((id: string) => {
        const noteInStore = find(notesStore.boardNotes, { id })
        if (noteInStore) {
          notesPositions.push({
            left: noteInStore.left,
            top: noteInStore.top,
          })
        }
      })

      selectionStore.setSelectionBox(getAdjustedSelectionBox(notesPositions))
    }
  }, [
    selectionStore.selecting,
    notesStore.boardNotes, // handle note movement while it's selected
    notesStore.notesIdsSelected, // handle CMD+click on a note
    selectionStore,
  ])

  return (
    <div
      className="board"
      id="board"
      ref={drop}
      onMouseUp={onMouseUp}
      onDoubleClick={onDoubleClickBoard}
    >
      <NotesList
        store={notesStore.boardNotesNotSelected}
        boardId={boardId}
        selectedIds={notesStore.notesIdsToSelect}
        toggleNoteSelected={toggleNoteSelected}
      />

      {board && board.columns.length ? <Columns boardId={boardId} /> : null}
      {!notesStore.boardNotesCount &&
        (!board || (board && !board.columns.length)) && <EmptyBoardTip />}

      <MouseSelection clearSelection={clearSelection} />

      <Selection
        isDraggingSelection={isDraggingSelection}
        drag={selectionDrag}
        deleteSelectedNotes={deleteSelectedNotes}
        moveSelectedNotesToDrawer={moveSelectedNotesToDrawer}
        clearSelection={clearSelection}
        notesList={
          <NotesList
            store={notesStore.boardNotesSelected}
            boardId={boardId}
            selectedIds={notesStore.notesIdsSelected}
            toggleNoteSelected={toggleNoteSelected}
          />
        }
      />

      <BoardDragLayer />

      {/* <div className="selection-box-info">
          <div>top: {selectionBox?.top || ''}</div>
          <div>left: {selectionBox?.left || ''}</div>
          <div>width: {selectionBox?.width || ''}</div>
          <div>height: {selectionBox?.height || ''}</div>
        </div> */}
    </div>
  )
})

export default Board
