export const windowHorizontalPadding = 30

interface BoardDimensions {
  width: number
  height: number
}

export const noteSize = 132

export const defaultBoardDimensions = {
  width: 780,
  height: 500,
}

export const minimumBoardDimensions = {
  width: 720,
  height: 670,
}

export const maxBoardDimensions = 10000

export const getNewBoardDimensions = (): BoardDimensions => {
  let width = window.innerWidth - 2 * windowHorizontalPadding
  let height = window.innerHeight - windowHorizontalPadding

  if (width < minimumBoardDimensions.width) {
    width = minimumBoardDimensions.width
  }
  if (height < minimumBoardDimensions.height) {
    height = minimumBoardDimensions.height
  }

  return { width, height }
}

export const toolbarWidth = 60
export const headerHeight = 75
