import React from 'react'
import { ReactComponent as UserIcon } from './assets/icon_user.svg'
import './assets/UserThumbnail.scss'

interface Props {
  dark?: boolean
}

const UserThumbnail: React.FC<Props> = ({ dark }) => {
  return (
    <div className={`user-thumbnail ${dark ? 'user-thumbnail_dark' : ''}`}>
      <UserIcon fill={dark ? '#fff' : '#2a2b2b'} />
    </div>
  )
}

export default UserThumbnail
