import { observable, action, makeObservable } from 'mobx'

export class UiStore {
  drawerOpen: string = ''
  setDrawerOpen = (drawer: string) => {
    this.drawerOpen = drawer
  }

  authModalOpen: boolean = false
  setAuthModalOpen = (open: boolean) => {
    this.authModalOpen = open
  }

  modalOpen: string = ''
  setModalOpen = (modalName: string) => {
    this.modalOpen = modalName
  }
  closeModal = () => {
    this.modalOpen = ''
  }

  secondaryModalOpen: string = ''
  setSecondaryModalOpen = (modalName: string) => {
    this.secondaryModalOpen = modalName
  }
  closeSecondaryModal = () => {
    this.secondaryModalOpen = ''
  }

  notificationOpen: string = ''
  setNotificationOpen = (id: string) => {
    this.notificationOpen = id
  }

  resetStore = () => {
    this.drawerOpen = ''
    this.authModalOpen = false
    this.modalOpen = ''
    this.secondaryModalOpen = ''
    this.notificationOpen = ''
  }

  constructor() {
    makeObservable(this, {
      drawerOpen: observable,
      setDrawerOpen: action,

      authModalOpen: observable,
      setAuthModalOpen: action,

      modalOpen: observable,
      setModalOpen: action,
      closeModal: action,

      secondaryModalOpen: observable,
      setSecondaryModalOpen: action,
      closeSecondaryModal: action,

      notificationOpen: observable,
      setNotificationOpen: action,

      resetStore: action,
    })
  }
}
