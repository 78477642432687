import React from 'react'
import { toast } from 'react-toastify'
import { Analytics } from '../../../services/analytics'
import { Api } from '../../../services/api'
import { maxCharacters } from '../../../util/config'
import { text } from '../../../util/text'
import EditableText from '../../EditableText/EditableText'
import './BoardTitle.scss'

interface Props {
  boardId: string | null
  title: string
  readOnly?: boolean
}

const BoardTitle: React.FC<Props> = ({ boardId, readOnly, title }) => {
  const updateBoardTitle = (title: string) => {
    if (boardId) {
      Api.updateBoardTitle(boardId, title)
        .then(() => {})
        .catch((error) => {
          toast.error(text.updateBoardTitleError)
          Analytics.logUpdateBoardTitle({
            success: false,
            error,
          })
        })
    }
  }

  return (
    <h1 className="board-title">
      {readOnly ? (
        <span>{title}</span>
      ) : (
        <EditableText
          text={title}
          save={updateBoardTitle}
          maxLength={maxCharacters.boardTitle}
        />
      )}
    </h1>
  )
}

export default BoardTitle
