export const defaultAppTitle = 'Tryangly'

export const maxCharacters = {
  username: 40,
  columnTitle: 40,
  boardTitle: 80,
}

export const toastAutoCloseTime = 4000

export const tooltipShowDelay = 1500
