import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'
import { observable, action, makeObservable, computed } from 'mobx'

export class SavedBoardsStore {
  boards: any = []

  setBoards = (boards: any) => {
    this.boards = boards
  }

  saveBoard = ({
    id,
    title,
    owned,
  }: {
    id: string
    title: string
    owned: boolean
  }) => {
    this.boards = {
      ...this.boards,
      [id]: {
        title,
        owned,
      },
    }
  }

  get boardsSorted() {
    const boards: any[] = []

    forEach(this.boards, (board: any, id: string) => {
      boards.push({
        id,
        ...board,
      })
    })

    return sortBy(boards, 'savedAt')
  }

  constructor() {
    makeObservable(this, {
      boards: observable,
      setBoards: action,
      saveBoard: action,
      boardsSorted: computed,
    })
  }
}
