import { SessionStore } from './SessionStore'
import { BoardStore } from './BoardStore'
import { NotesStore } from './NotesStore'
import { ColorPickerStore } from './ColorPickerStore'
import { UsersStore } from './UsersStore'
import { SavedBoardsStore } from './SavedBoardsStore'
import { SelectionStore } from './SelectionStore'
import { UiStore } from './UiStore'

export class RootStore {
  sessionStore: SessionStore
  boardStore: BoardStore
  notesStore: NotesStore
  colorPickerStore: ColorPickerStore
  usersStore: UsersStore
  savedBoardsStore: SavedBoardsStore
  selectionStore: SelectionStore
  uiStore: UiStore

  constructor() {
    this.sessionStore = new SessionStore()
    this.boardStore = new BoardStore()
    this.notesStore = new NotesStore()
    this.colorPickerStore = new ColorPickerStore()
    this.usersStore = new UsersStore()
    this.savedBoardsStore = new SavedBoardsStore()
    this.selectionStore = new SelectionStore()

    // UI state
    this.uiStore = new UiStore()
  }
}
