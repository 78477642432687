export const text = {
  tryangly: 'Tryangly',
  create: 'Create',
  createBoard: 'Create a board',
  boardTitle: 'Board title',

  tryDemoBoard: 'Try our demo board',

  demoBoardNotificationLine1: 'This is a public demo board.',
  demoBoardNotificationLine2:
    'You can create your own private board on the home page.',

  deleteBoard: 'Delete the board',
  clearOrDeleteBoard: 'Clear or delete the board',
  clearBoard: 'Clear the board',
  clearDrawer: 'Clear the drawer',
  boardDeleted: 'Board deleted',
  boardHasBeenDeleted: 'Board has been deleted',

  boardSize: 'Board size',
  width: 'Width',
  height: 'Height',

  exportCsv: 'Export to CSV',

  importNotes: 'Import notes',
  import: 'Import',
  importToBoard: 'Import to the board',
  importToDrawer: 'Import to the drawer',
  maxImportLines: (max: number) => `You can import ${max} notes at a time.`,

  users: 'Users',
  // participants: 'Participants',
  usersOnline: 'Users online',

  errorGeneric: 'Something went wrong, sorry. Please try again.',
  createBoardError: 'Could not create the board, please try again',
  deleteBoardError: 'Could not delete the board, please try again',
  updateBoardError: 'Could not update the board, please try again',
  updateBoardTitleError: 'Could not update the title, please try again',
  loadBoardError: 'Could not load this board, please try again',
  loadAllBoardsError: 'Could not load boards',
  boardDoesNotExist: 'This board does not exist',
  clearBoardError: 'Could not clear the board, please try again',
  boardPermissionDenied: 'You do not have access to this board.',

  addNote: 'Add a note',
  removeNote: 'Remove note',
  createNoteError: 'Could not create a note, please try again',
  updateNoteError: 'Could not update the note, please try again',
  deleteNoteError: 'Could not delete the note, please try again',
  loadBoardNotesError: 'Could not load notes, please try again',
  updateUsernameError: 'Could not update your username, please try again',

  addColumn: 'Add a column',
  addColumnError: 'Could not add a column, please try again',
  notEnoughSpaceForColumn: 'Not enough space for another column.',
  youCanResizeBoard:
    'You can resize the board to fit more items – just click and drag the bottom-right corner.',
  updateColumnError: 'Could not update this column, please try again',
  deleteColumnError: 'Could not delete this column, please try again',

  // userJoined: (name: string): string => `${name} joined`,
  // userLeft: (name: string): string => `${name} left`,

  resizeBoard: 'Resize the board',
  clickAndDragResize: 'Click and drag to resize',

  copyTextSuccess: 'Text copied to clipboard',
  copyTextError: 'Could not copy text',

  touchSupportNotification:
    'Tryangly does not fully support touch screens right now, so some things might not work as expected. We are working on it!',

  youAreOffline: 'You are offline',

  importNotesInfo:
    'Type or paste a list of items – each line will add a new note.',

  cancel: 'Cancel',
  name: 'Name',
  you: 'You',
  yourName: 'Your name',
  yourEmail: 'Your email',
  yourNewEmail: 'Your new email',
  save: 'Save',
  update: 'Update',
  edit: 'Edit',
  or: 'or',
  changeName: 'Change name',
  changeEmail: 'Change email',
  passwordConfirmEmail: 'Enter your password to confirm',
  apply: 'Apply',
  anonymous: 'Anonymous',
  close: 'Close',
  contactUs: 'Contact us',

  chooseColor: 'Choose a color',
  randomColor: 'Random color',
  selectedColor: 'Selected:',
  currentlyRandom: 'Currently using random colors',
  drawer: 'Drawer',
  notesDrawerEmpty: `Drop some notes here or\u00A0double\u00A0click to add`,
  justYou: "It's just you",
  lockedNoteTitle: 'Someone else is editing…',

  aboutTryangly: 'About Tryangly',
  builtBy: 'Built by',
  shortcuts: 'Shortcuts',
  tipsAndShortcuts: 'Tips & Shortcuts',
  // keyboardShortcuts: 'Keyboard shortcuts',

  rights: 'All rights reserved.',

  submit: 'Submit',
  signUp: 'Sign up',
  signUpSuccess: 'Account created successfully!',
  emailVerificationSent:
    'Please check your inbox for a\u00A0verification email.',
  signIn: 'Sign in',
  signOut: 'Sign out',
  email: 'Email',
  password: 'Password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  passwordsDontMatch: 'Passwords do not match',
  resetPassword: 'Reset password',
  changePassword: 'Change password',
  hidePassword: 'Hide password',
  showPassword: 'Show password',
  passwordInvalid: 'The password is incorrect',
  account: 'Account',
  deleteAccount: 'Delete account',
  deleteAccountConfirm:
    'Enter your password to confirm that you wish to delete your account. This will not delete your boards.',
  deleteAccountSuccess: 'Account deleted',
  deleteAccountError: 'Could not delete the account. Please try again.',
  createAccountOrAuth: 'Create an account or sign in',
  toInviteViaEmail: 'to invite others via email.',

  savedBoards: 'Saved boards',
  saveBoard: 'Add to saved boards',
  inSavedBoards: 'In your saved boards',
  removeSavedBoard: 'Remove from saved boards',
  // confirmRemoveSavedBoard:
  //   'Are you sure you wish to remove this from your saved boards? This action will not delete the board.',
  remove: 'Remove',
  savedBoardAuthPrompt: 'Sign in to keep a\u00A0list of\u00A0saved boards.',
  savedBoardsMessage: 'Boards you create or save will be listed here.',

  yourProfile: 'Your profile',
  boards: 'Boards',

  shareBoard: 'share the board',
  shareBoardStandalone: 'Share the board',
  invite: 'Invite',
  copyBoardUrl: 'Copy board URL',
  copy: 'Copy',
  copied: 'Copied',
  send: 'Send',
  sending: 'Sending',
  sent: 'Email sent',

  inviteViaEmail: 'Invite via email',
  inviteViaEmailSuccess: (email: string) => `Invitation sent to ${email}`,
  inviteViaEmailError: 'Something went wrong, sorry. Please try again.',

  changeTheme: 'Change theme',
  darkTheme: 'Dark theme',

  selection: 'Selection',
  moveSelectedNotes: 'Move selected notes',
  deleteSelectedNotes: 'Delete selected notes',
  moveSelectedToDrawer: 'Move selected notes to drawer',
}

// Prevent multiline text in editable titles
export const handleChangeSingleLineContentEditable = (
  value: string
): string => {
  const node = document.createElement('div')
  node.innerHTML = value
  value = node.innerText.replace(/\n/g, ' ').trim().replace(/\s+/g, ' ')

  return value
}

export const stripHtml = (html: string): string => {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const sanitizeNoteText = (text: string): string => {
  text = text.replaceAll('&nbsp;', ' ')
  text = stripHtml(text)

  return text.trim()
}
