import React from 'react'
import { useRootStore } from '../../../store/RootStateProvider'
import { Api } from '../../../services/api'
import { text } from '../../../util/text'
import { observer } from 'mobx-react-lite'
import ReactTooltip from 'react-tooltip'
import { tooltipShowDelay } from '../../../util/config'
import AuthPromptModal from './AuthPrompt/AuthPromptModal'
import './assets/SavedBoardToggle.scss'
import { ReactComponent as SavedIcon } from './assets/ico_saved-board-toggle.svg'

interface Props {
  enableTooltip?: boolean
  location?: 'profile'
  disabled?: boolean
}

const SavedBoardToggle: React.FC<Props> = observer(
  ({ enableTooltip, location, disabled }): JSX.Element | null => {
    const {
      savedBoardsStore,
      boardStore,
      sessionStore,
      uiStore,
    } = useRootStore().rootStore

    const user = sessionStore.currentUser
    const saved = !!(
      boardStore.boardId && savedBoardsStore.boards[boardStore.boardId]
    )

    const saveBoard = () => {
      if (boardStore.boardId && boardStore.board) {
        const b = {
          id: boardStore.boardId,
          title: boardStore.board.title,
          owned: boardStore.board.owner?.id === sessionStore.currentUser.id,
        }

        Api.saveBoard(b).then(() => {
          savedBoardsStore.saveBoard(b)
        })
      }
    }

    const removeSavedBoard = () => {
      if (boardStore.boardId) {
        const boards = savedBoardsStore.boards
        delete boards[boardStore.boardId]

        Api.updateSavedBoards(boards).then(() => {
          savedBoardsStore.setBoards(boards)
        })
      }
    }

    const handleChange = () => {
      if (user && !user.isAnonymous) {
        if (saved) {
          removeSavedBoard()
        } else {
          saveBoard()
        }
      } else {
        uiStore.setModalOpen('save-board-auth-prompt')
      }
    }

    return (
      <div>
        <label
          className={`saved-board-toggle 
            ${saved && 'saved-board-toggle__saved'}
            ${location === 'profile' && 'saved-board-toggle_profile'}
            ${disabled && 'saved-board-toggle__disabled'}
            `}
          data-tip={text.saveBoard}
          data-for="save-board-tooltip"
        >
          <input
            type="checkbox"
            onChange={handleChange}
            checked={saved}
            disabled={disabled}
          />
          <span className="hide">{text.inSavedBoards}</span>
          <SavedIcon />
        </label>

        {enableTooltip && (
          <ReactTooltip
            id="save-board-tooltip"
            place="bottom"
            effect="solid"
            delayShow={tooltipShowDelay}
            getContent={() => (saved ? text.inSavedBoards : text.saveBoard)}
          />
        )}

        <AuthPromptModal />
      </div>
    )
  }
)

export default SavedBoardToggle
