import React from 'react'
import { toast } from 'react-toastify'
import { toolbarWidth } from '../../util/dimensions'
import { text } from '../../util/text'
import { isTouchScreen } from '../../util/touch'

const getBoardPositionFromTop = (): number => {
  const board = document.querySelector('.board-container')
  if (board !== null && board instanceof HTMLElement) {
    return board.offsetTop
  }

  return 0
}

export const getCursorPositionOnBoard = (
  e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>
): { cursorX: number; cursorY: number } => {
  return {
    cursorX: e.clientX + window.scrollX - toolbarWidth,
    cursorY: e.clientY + window.scrollY - getBoardPositionFromTop(),
  }
}

export const getNewNotePosition = (
  e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>
): {
  top: number
  left: number
} => {
  const { cursorX, cursorY } = getCursorPositionOnBoard(e)

  return {
    top: cursorY - 16,
    left: cursorX - 48,
  }
}

export const showTouchSupportNotification = () => {
  if (isTouchScreen()) {
    if (!localStorage.getItem('noTouchSupportNotified')) {
      toast.info(text.touchSupportNotification, {
        autoClose: false,
      })
      localStorage.setItem('noTouchSupportNotified', 'true')
    }
  }
}

export const showDemoBoardNotification = () => {
  // if (!localStorage.getItem('demoBoardNotified')) {
  //   // setTimeout(() => {
  //   toast.info(
  //     <MultiLineToast
  //       lines={[
  //         text.demoBoardNotificationLine1,
  //         text.demoBoardNotificationLine2,
  //       ]}
  //     />
  //   )
  //   localStorage.setItem('demoBoardNotified', 'true')
  //   // }, 1000)
  // }
}
