import find from 'lodash/find'
import React, { useContext, useCallback } from 'react'
import {
  Box,
  boxesIntersect,
  useSelectionContainer,
} from 'react-drag-to-select'
import { ThemeContext } from '../../Theme/ThemeProvider'
import './Selection.scss'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../../store/RootStateProvider'
import { noteSize } from '../../../util/dimensions'

interface Props {
  clearSelection: () => void
}

const MouseSelection: React.FC<Props> = observer(({ clearSelection }) => {
  const { theme } = useContext(ThemeContext)
  const { notesStore, selectionStore } = useRootStore().rootStore

  const onSelectionStart = useCallback(() => {
    clearSelection()
    selectionStore.setSelecting(true)
  }, [selectionStore, clearSelection])

  const onSelectionChange = useCallback(
    (box: Box) => {
      if (box.width > 10 && box.height > 10) {
        selectionStore.setSelectionBox(box)

        const notesToSelect: any[] = []
        notesStore.boardNotes.forEach((item: any) => {
          const note = {
            ...item,
            width: noteSize,
            height: noteSize,
          }

          if (boxesIntersect(box, note)) {
            notesToSelect.push(note.id)
          }
        })

        notesStore.setNotesIdsToSelect(notesToSelect)
      } else {
        // notesStore.setNotesIdsToSelect([]) ???
        selectionStore.setSelectionBox(null)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notesStore.boardNotes, notesStore, selectionStore]
  )

  const onSelectionEnd = useCallback(() => {
    selectionStore.setSelecting(false)

    // Check if notes are still on the board before storing selection:
    // could be deleted or moved to drawer by someone else
    // in the mean time, causing incorrect selection.
    const idsToSelect: string[] = []
    notesStore.notesIdsToSelect.forEach((id) => {
      if (find(notesStore.boardNotes, { id })) {
        idsToSelect.push(id)
      }
    })

    notesStore.setNotesIdsSelected(idsToSelect)
    notesStore.setNotesIdsToSelect([])
  }, [selectionStore, notesStore])

  const style =
    theme === 'dark'
      ? {
          border: '1px dashed rgba(160, 160, 160, 0.6)',
          borderRadius: 4,
          background: 'rgba(92, 93, 95, 0.2)',
          zIndex: 0,
        }
      : {
          border: '1px dashed rgba(12, 90, 180, 0.3)',
          borderRadius: 4,
          background: 'rgba(12, 90, 180, 0.04)',
          zIndex: 0,
        }

  const { DragSelection } = useSelectionContainer({
    eventsElement: document.getElementById('board'),
    onSelectionStart,
    onSelectionChange,
    onSelectionEnd,
    selectionProps: {
      style,
    },
  })

  return <DragSelection />
})

export default MouseSelection
