import { Box } from 'react-drag-to-select'
import {
  toolbarWidth,
  headerHeight,
  windowHorizontalPadding,
  noteSize,
} from '../../util/dimensions'

export const getNoteCoordsOnDrop = (monitor: any) => {
  const clientOffset = monitor.getSourceClientOffset()

  let left =
    clientOffset.x + window.scrollX - toolbarWidth - windowHorizontalPadding
  let top = clientOffset.y + window.scrollY - headerHeight

  if (left < 0) {
    left = 10
  }

  if (top < 0) {
    top = 10
  }

  return { left, top }
}

export const getSelectionDropDiff = (
  monitor: any,
  selectionBox: Box | null,
  boardDimensions: { width: number; height: number }
) => {
  const clientOffset = monitor.getSourceClientOffset()
  let diff = monitor.getDifferenceFromInitialOffset()

  let selectionLeft =
    clientOffset.x + window.scrollX - toolbarWidth - windowHorizontalPadding
  let selectionTop = clientOffset.y + window.scrollY - headerHeight

  // Negative left/top means the selection was moved off the
  // board, which is not allowed. Reset the diff and put it back.
  if (selectionLeft < 0 || selectionTop < 0) {
    diff = {
      x: 0,
      y: 0,
    }
  } else {
    if (!selectionBox) return

    const selectionRight = selectionLeft + selectionBox.width
    const selectionBottom = selectionTop + selectionBox.height
    const boardRight =
      boardDimensions.width - toolbarWidth - windowHorizontalPadding
    const boardBottom = boardDimensions.height - headerHeight

    if (selectionRight > boardRight || selectionBottom > boardBottom) {
      diff = {
        x: 0,
        y: 0,
      }
    }
  }

  return diff
}

export const getAdjustedSelectionBox = (
  notesPositions: { left: number; top: number }[]
) => {
  const lefts = notesPositions.map((p) => p.left)
  const tops = notesPositions.map((p) => p.top)

  const minLeft = Math.min(...lefts)
  const maxLeft = Math.max(...lefts)
  const minTop = Math.min(...tops)
  const maxTop = Math.max(...tops)

  return {
    left: minLeft - 10,
    top: minTop - 10,
    width: maxLeft - minLeft + noteSize + 20,
    height: maxTop - minTop + noteSize + 20,
  }
}
