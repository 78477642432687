import React, { useContext } from 'react'
import { maxCharacters } from '../../util/config'
import ButtonRemove from '../Buttons/ButtonRemove/ButtonRemove'
import EditableText from '../EditableText/EditableText'
import { ThemeContext } from '../Theme/ThemeProvider'
import './Columns.scss'

interface ColumnTitleComponent {
  index: number
  title: string
  style: any
  updateColumnTitle: (index: number, text: string) => void
  removeColumn: (index: number) => void
}

const ColumnTitle: React.FC<ColumnTitleComponent> = (props) => {
  const { theme } = useContext(ThemeContext)

  const updateTitle = (title: string) =>
    props.updateColumnTitle(props.index, title)

  return (
    <div className="column__title" style={props.style}>
      <div>
        <EditableText
          text={props.title}
          save={updateTitle}
          maxLength={maxCharacters.columnTitle}
        />

        <ButtonRemove
          onClick={() => props.removeColumn(props.index)}
          label={`Remove column ${props.title}`}
          color={theme === 'dark' ? '#fff' : ''}
        />
      </div>
    </div>
  )
}

export default ColumnTitle
