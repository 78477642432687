import React from 'react'
import './Tabs.scss'

interface Props {
  active: boolean
  disabled?: boolean
  text: string
  onPress?: any
}

const Tab: React.FC<Props> = ({ active, disabled, text, onPress }) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onPress}
      className={`tab ${active && 'tab_active'} ${disabled && 'tab_disabled'}`}
    >
      {text}
    </button>
  )
}

export default Tab
