import React from 'react'
import { ReactComponent as CloseIcon } from './assets/icons/icon_close.svg'

interface CloseButtonComponent {
  text: string
  onPress: (e: React.MouseEvent<HTMLButtonElement>) => void
  type?: 'delete' | ''
}

const ToastifyCloseButton = ({
  text,
  onPress,
  type,
}: CloseButtonComponent): React.ReactElement => {
  return (
    <button
      aria-label="Close notification"
      className="Toastify__close-button"
      onClick={onPress}
    >
      <CloseIcon />
    </button>
  )
}

export default ToastifyCloseButton
