import React from 'react'

type Props = {
  lines: string[]
}

const MultiLineToast: React.FC<Props> = ({ lines }) => {
  return (
    <div>
      {lines.map((l, index) => {
        return <p key={index}>{l}</p>
      })}
    </div>
  )
}

export default MultiLineToast
