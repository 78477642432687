import { BoardInterface } from '../../util/interfaces'
import { Api } from '../../services/api'
import {
  defaultBoardDimensions,
  minimumBoardDimensions,
} from '../../util/dimensions'
import ResizeHandle from './ResizeHandle'

export interface ResizeCallbackData {
  width: number
  height: number
}

export const getResizableConfig = (
  boardId: string | null,
  board: BoardInterface | null,
  minimumBoardWidth: number
) => {
  let width: number = board?.width || defaultBoardDimensions.width
  let height: number = board?.height || defaultBoardDimensions.height
  const enable = !!board

  return {
    enable: {
      top: false,
      right: enable,
      bottom: enable,
      left: false,
      topRight: false,
      bottomRight: enable,
      bottomLeft: false,
      topLeft: false,
    },
    size: {
      width,
      height,
    },
    handleComponent: {
      bottomRight: <ResizeHandle />,
    },
    onResizeStop: (
      e: any,
      direction: any,
      ref: any,
      delta: ResizeCallbackData
    ) => {
      if (boardId) {
        width = width + delta.width
        height = height + delta.height

        if (boardId) {
          Api.updateBoardDimensions(boardId, width, height)
        }
      }
    },
    handleClasses: {
      right: 'resize-handle resizable__handle_right',
      bottom: 'resize-handle resizable__handle_bottom',
      bottomRight: 'resize-handle resizable__handle_bottom-right',
    },
    minWidth: minimumBoardWidth,
    minHeight: board ? minimumBoardDimensions.height : 0,
  }
}
