import React from 'react'
import { text } from '../../../../util/text'
import './assets/SelectionToolbar.scss'
import SelectionToolbarButton from './SelectionToolbarButton'
import useHotKeys from '@reecelucas/react-use-hotkeys'

import { ReactComponent as IconRemove } from '../../../Toolbar/ToolbarIcon/assets/icon_remove.svg'
import { ReactComponent as IconDrawer } from '../../../Toolbar/ToolbarIcon/assets/icon_drawer.svg'
import { ReactComponent as IconMove } from './assets/icon_move.svg'
// import ReactTooltip from 'react-tooltip'

interface Props {
  deleteSelectedNotes?: () => void
  moveSelectedNotesToDrawer?: () => void
  clearSelection?: () => void
}

const SelectionToolbar: React.FC<Props> = ({
  deleteSelectedNotes,
  moveSelectedNotesToDrawer,
}) => {
  useHotKeys('Backspace', () => {
    if (deleteSelectedNotes) {
      deleteSelectedNotes()
    }
  })

  return (
    <div className="selected-notes-toolbar" data-draggable={true}>
      <SelectionToolbarButton
        icon={<IconMove />}
        onClick={() => null}
        text={text.moveSelectedNotes}
        style={{ cursor: 'grab' }}
      />

      <SelectionToolbarButton
        icon={<IconDrawer />}
        onClick={moveSelectedNotesToDrawer}
        text={text.moveSelectedToDrawer}
      />

      <SelectionToolbarButton
        icon={<IconRemove />}
        onClick={deleteSelectedNotes}
        text={text.deleteSelectedNotes}
      />

      {/* <ReactTooltip
        id="selection-toolbar-tooltip"
        place="bottom"
        effect="solid"
        delayShow={tooltipShowDelay}
      /> */}
    </div>
  )
}

export default SelectionToolbar
