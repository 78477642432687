import React, { useEffect, useState } from 'react'
import './assets/EmptyBoardTip.scss'
import { ReactComponent as NoteImage } from './assets/img_note.svg'

const EmptyBoardTip: React.FC<{}> = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!visible) {
      setVisible(true)
    }
  }, [visible])

  return (
    <div
      className="empty-board-tip"
      style={{
        opacity: visible ? 1 : 0,
      }}
    >
      <div>
        <NoteImage />
        <span>
          <b>Double click</b> to add a note
        </span>
      </div>
    </div>
  )
}

export default EmptyBoardTip
