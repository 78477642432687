import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { useRootStore } from '../../../store/RootStateProvider'
import { text } from '../../../util/text'
import ButtonGeneric from '../../Buttons/ButtonGeneric/ButtonGeneric'
import { Api } from '../../../services/api'
import {
  maxBoardDimensions,
  minimumBoardDimensions,
} from '../../../util/dimensions'
import './BoardSizeModal.scss'
import { isValidBoardSize } from './utils'
import { observer } from 'mobx-react-lite'

const BoardSizeModal: React.FC<{}> = observer((): JSX.Element | null => {
  const { boardStore, uiStore } = useRootStore().rootStore
  const { board, boardId } = boardStore

  const isOpen = uiStore.modalOpen === 'board-size'
  const [rendered, setRendered] = useState(false)

  const [width, setWidth] = useState(board?.width || 0)
  const [height, setHeight] = useState(board?.height || 0)
  const [validWidth, setValidWidth] = useState(true)
  const [validHeight, setValidHeight] = useState(true)

  const isValidWidth = (value: number) => {
    return isValidBoardSize(
      value,
      boardStore.minimumBoardWidth,
      maxBoardDimensions
    )
  }

  const isValidHeight = (value: number) => {
    return isValidBoardSize(
      value,
      minimumBoardDimensions.height,
      maxBoardDimensions
    )
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' && Number.isNaN(parseInt(e.key))) {
      e.preventDefault()
    }
  }

  const onWidthChange = (inputValue: string): boolean => {
    // TODO: handlind empty input (throws an error, but doesn't break it)
    let value = parseInt(inputValue)
    setWidth(value)

    const valid = isValidWidth(value)
    setValidWidth(valid)
    return valid
  }

  const onHeightChange = (inputValue: string): boolean => {
    let value = parseInt(inputValue)
    setHeight(value)

    const valid = isValidHeight(value)
    setValidHeight(valid)
    return valid
  }

  const onSubmit = (
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()

    if (isValidWidth(width) && isValidHeight(height) && boardId) {
      // Save and close
      Api.updateBoardDimensions(boardId, width, height)
      uiStore.closeModal()
    }
  }

  useEffect(() => {
    if (isOpen) {
      setRendered(true)

      if (board) {
        setWidth(board.width)
        setHeight(board.height)
      }
    } else {
      setTimeout(() => {
        setRendered(false)
        setValidWidth(true)
        setValidHeight(true)
      }, 500)
    }
  }, [isOpen, board])

  return rendered ? (
    <Modal
      open={isOpen}
      onClose={uiStore.closeModal}
      center={true}
      classNames={{ modal: 'modal-board-size' }}
      showCloseIcon={false}
    >
      <form onSubmit={onSubmit}>
        <h1 className="modal__title" tabIndex={0}>
          {text.boardSize}
        </h1>

        <div className="inputs-row">
          <div className={`${validWidth ? '' : 'input_invalid'}`}>
            <label htmlFor="board-size-width">{text.width}</label>
            <input
              id="board-size-width"
              type="number"
              value={width}
              onChange={(e) => onWidthChange(e.target.value)}
              onKeyPress={onKeyPress}
              min={boardStore.minimumBoardWidth}
              max={maxBoardDimensions}
            />
            {!validWidth && (
              <p className="input-message">
                Min {boardStore.minimumBoardWidth}, max {maxBoardDimensions} px
              </p>
            )}
          </div>
          <span className="inputs-row__text">𝗑</span>

          <div className={`${validHeight ? '' : 'input_invalid'}`}>
            <label htmlFor="board-size-height">{text.height}</label>
            <input
              id="board-size-height"
              type="number"
              value={height}
              onChange={(e) => onHeightChange(e.target.value)}
              onKeyPress={onKeyPress}
              min={minimumBoardDimensions.height}
              max={maxBoardDimensions}
            />

            {!validHeight && (
              <p className="input-message">
                Min {minimumBoardDimensions.height}, max {maxBoardDimensions} px
              </p>
            )}
          </div>
        </div>

        <div className="modal-buttons__stack">
          <ButtonGeneric
            htmlButtonType="submit"
            text={text.save}
            onPress={onSubmit}
          />

          <ButtonGeneric
            text={text.cancel}
            type="cancel"
            onPress={uiStore.closeModal}
          />
        </div>
      </form>
    </Modal>
  ) : null
})

export default BoardSizeModal
