import { observable, action, computed, makeObservable } from 'mobx'
import { getRandomColor } from '../components/Note/util'

export class ColorPickerStore {
  selectedColor: string = getRandomColor()

  selectColor = (color: string) => {
    this.selectedColor = color
    this.randomColor1 = ''
  }

  randomColor1: string = getRandomColor()
  randomColor2: string = getRandomColor()

  get currentColor(): string {
    return this.randomColor1 || this.selectedColor
  }

  toggleRandomColor = () => {
    if (this.randomColor1) {
      this.randomColor1 = ''
    } else {
      if (this.randomColor2) {
        this.randomColor1 = this.randomColor2
        this.randomColor2 = getRandomColor()
      } else {
        this.randomColor1 = getRandomColor()
        this.randomColor2 = getRandomColor()
      }
    }
  }

  getNewRandomColors = () => {
    if (this.randomColor1) {
      this.randomColor1 = this.randomColor2 || getRandomColor()
      this.randomColor2 = getRandomColor()
    }
  }

  constructor() {
    makeObservable(this, {
      selectedColor: observable,
      randomColor1: observable,
      randomColor2: observable,
      selectColor: action,
      toggleRandomColor: action,
      getNewRandomColors: action,
      currentColor: computed,
    })
  }
}
