import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../../store/RootStateProvider'
import { text } from '../../../util/text'
import ButtonGeneric from '../../Buttons/ButtonGeneric/ButtonGeneric'
import PasswordInput from '../../Forms/PasswordInput/PasswordInput'
import { Api } from '../../../services/api'
import ModalTitle from '../../ModalTitle/ModalTitle'

const ProfileEmail: React.FC<{}> = observer(() => {
  const { sessionStore, uiStore } = useRootStore().rootStore
  const user = sessionStore.currentUser

  const [email, setEmail] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)

  const [password, setPassword] = useState('')
  const [invalidPassword, setInvalidPassword] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [busy, setBusy] = useState(false)

  const onChangeEmail = (e: any) => {
    setErrorMsg('')
    setInvalidEmail(false)
    setEmail(e.target.value)
  }

  const onChangePassword = (e: any) => {
    setErrorMsg('')
    setInvalidPassword(false)
    setPassword(e.target.value)
  }

  const handleError = (error: { code: string; message: string }) => {
    setErrorMsg(error.message)

    switch (error.code) {
      case 'auth/wrong-password':
        setInvalidPassword(true)
        break

      case 'auth/email-already-in-use':
        setInvalidEmail(true)
        break

      default:
        break
    }
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    if (busy) return
    setErrorMsg('')

    if (!email) {
      setInvalidEmail(true)
      return
    }

    if (!password) {
      setInvalidPassword(true)
      return
    }

    if (email === user.email) {
      // No change, just close
      uiStore.closeSecondaryModal()
      return
    }

    setBusy(true)

    Api.reAuthenticate(user.email, password)
      .then(() => {
        Api.updateUserEmail(email)
          .then(() => {
            sessionStore.updateCurrentUserProfile({ email })

            setBusy(false)
            uiStore.closeSecondaryModal()
          })
          .catch((error) => {
            handleError(error)
            setBusy(false)
          })
      })
      .catch((error) => {
        handleError(error)
        setBusy(false)
      })
  }

  return (
    <section>
      <ModalTitle text={text.changeEmail} />

      <form onSubmit={onSubmit}>
        <label htmlFor="profile-email">{text.yourNewEmail}</label>
        <div
          className={`input-container ${invalidEmail ? 'input_invalid' : ''}`}
        >
          <input
            type="email"
            id="profile-email"
            value={email}
            onChange={onChangeEmail}
            disabled={busy}
          />
        </div>

        <label htmlFor="profile-email-password">{text.password}</label>
        <PasswordInput
          id="profile-email-password"
          password={password}
          onChange={onChangePassword}
          invalid={invalidPassword}
          placeholder={text.passwordConfirmEmail}
          disabled={busy}
        />

        {errorMsg ? <p className="form-error-msg">{errorMsg}</p> : null}

        <div className="modal-buttons__stack">
          <ButtonGeneric
            htmlButtonType="submit"
            text={text.changeEmail}
            onPress={onSubmit}
            disabled={busy}
          />
        </div>
      </form>
    </section>
  )
})

export default ProfileEmail
