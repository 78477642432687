import React from 'react'
import './ModalTitle.scss'

interface Props {
  text: string
}

const ModalTitle: React.FC<Props> = ({ text }) => (
  <h2 className="modal-title">
    <span>{text}</span>
  </h2>
)

export default ModalTitle
