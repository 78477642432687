import React from 'react'
import './assets/SelectionToolbar.scss'

interface Props {
  icon: any
  onClick?: () => void
  text: string
  style?: any
}

const SelectionToolbarButton: React.FC<Props> = ({
  icon,
  onClick,
  text,
  style,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      aria-label={text}
      data-tip={text}
      data-for="selection-toolbar-tooltip"
      style={style || {}}
    >
      {icon}
    </button>
  )
}

export default SelectionToolbarButton
