import React from 'react'
import Note from '../Note/Note'
import { NoteInterface } from '../../util/interfaces'
import { observer } from 'mobx-react-lite'

interface Props {
  store: any
  boardId: string
  selectedIds: string[]
  toggleNoteSelected: (noteId: string) => void
}

const NotesList: React.FC<Props> = observer(
  ({ store, boardId, selectedIds, toggleNoteSelected }) => {
    return store.map((note: NoteInterface) => {
      return note.id ? (
        <Note
          boardId={boardId}
          key={note.id}
          selected={selectedIds.indexOf(note.id) > -1}
          toggleNoteSelected={toggleNoteSelected}
          {...note}
        />
      ) : null
    })
  }
)

export default NotesList
