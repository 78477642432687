import React, { useContext, useRef } from 'react'
import { ThemeContext } from '../../Theme/ThemeProvider'
import { ReactComponent as ThemeLightIcon } from './assets/ico_theme-light.svg'
import { ReactComponent as ThemeDarkIcon } from './assets/ico_theme-dark.svg'
import './assets/ThemeToggle.scss'
import { text } from '../../../util/text'

const ThemeToggle: React.FC<{}> = () => {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const rotation = useRef(theme === 'dark' ? 180 : 0)

  const onChange = () => {
    toggleTheme()
    rotation.current = rotation.current + 180
  }

  return (
    <div
      className={`theme-toggle ${theme === 'dark' ? 'theme-toggle_dark' : ''}`}
    >
      {/* <label data-tip={text.changeTheme} data-for="header-tooltip"> */}
      <label>
        <input type="checkbox" onChange={onChange} checked={theme === 'dark'} />
        <span className="hide">{text.darkTheme}</span>
      </label>

      <div className="icons">
        <div
          className="icons__inner"
          style={{ transform: `rotate(-${rotation.current}deg)` }}
        >
          <div className="icon icon-light">
            <ThemeLightIcon />
          </div>
          <div className="icon icon-dark">
            <ThemeDarkIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThemeToggle
