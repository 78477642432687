import React, { Suspense, useEffect } from 'react'
import useHotKeys from '@reecelucas/react-use-hotkeys'
import Board from '../Board/Board'
import { Api } from '../../services/api'
import Footer from '../Footer/Footer'
import { Analytics } from '../../services/analytics'
import './assets/BoardContainer.scss'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../store/RootStateProvider'
import { toast } from 'react-toastify'
import { text } from '../../util/text'
import ToolbarPlaceholder from '../Toolbar/Toolbar/ToolbarPlaceholder'
import { useHistory } from 'react-router-dom'
import { BoardInterface } from '../../util/interfaces'
import { undoRecentAction } from './util'

const Toolbar = React.lazy(() => import('../Toolbar/Toolbar/Toolbar'))

export interface BoardContainerProps {
  boardId: string | null
  changeBoard: (board: BoardInterface | null) => void
}

const BoardContainer: React.FC<BoardContainerProps> = observer(
  ({ boardId, changeBoard }) => {
    const history = useHistory()

    const { sessionStore, boardStore } = useRootStore().rootStore
    const { currentUser } = sessionStore

    let { board } = boardStore
    if (board && board.deleted) {
      board = null
    }

    useEffect(() => {
      let unsubscribeBoard: any = null

      if (boardId && currentUser) {
        if (boardId === process.env.REACT_APP_DEMO_BOARD_ID) {
          Analytics.logOpenDemoBoard()
        }

        unsubscribeBoard = Api.streamBoard(boardId, {
          next: (doc: any) => {
            const boardData = doc.data()

            if (boardData) {
              if (boardData.deleted) {
                changeBoard(null)
                history.replace('/')
                toast.info(text.boardHasBeenDeleted)
              } else {
                changeBoard(boardData)
              }
            } else {
              if (boardId) {
                toast.error(text.loadBoardError)

                // Leave the board, assume it was deleted
                history.replace('/')
              }
            }
          },
          error: (error: any) => {
            const parsedError = JSON.parse(JSON.stringify(error))

            if (parsedError && parsedError.code === 'permission-denied') {
              // Toast for now, alert modal would be better
              toast.error(text.boardPermissionDenied)
              history.replace('/')
            } else {
              toast.error(text.loadBoardError)

              Analytics.logStreamBoard({
                success: false,
                boardId,
                error,
              })
            }
          },
        })
      } else {
        if (unsubscribeBoard) {
          return unsubscribeBoard()
        }
      }

      sessionStore.resetRecentActions()

      return () => {
        if (unsubscribeBoard) {
          unsubscribeBoard()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardId, currentUser])

    useHotKeys('.+z', () => {
      if (sessionStore.recentActions.length) {
        undoRecentAction(
          sessionStore.recentActions[0],
          sessionStore.undoRecentAction
        )
      }
    })

    return (
      <div className="board-container">
        <div className="board-container__inner">
          <div className="board-gradient"></div>

          {currentUser && board && boardId ? (
            <Suspense fallback={<ToolbarPlaceholder />}>
              <Toolbar
                board={board}
                boardId={boardId}
                history={history}
                changeBoard={changeBoard}
                currentUser={currentUser}
              />

              <Board
                board={{ ...board }}
                boardId={boardId}
                userId={currentUser.id}
              />
            </Suspense>
          ) : null}

          <Footer location="board" />
        </div>
      </div>
    )
  }
)

export default BoardContainer
