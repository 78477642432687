import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../../store/RootStateProvider'
import { text } from '../../../util/text'
import ButtonGeneric from '../../Buttons/ButtonGeneric/ButtonGeneric'
import { Api } from '../../../services/api'
import ModalTitle from '../../ModalTitle/ModalTitle'
import { toast } from 'react-toastify'

interface Props {
  initialEmail?: string
}

const ResetPassword: React.FC<Props> = observer(({ initialEmail }) => {
  const { uiStore } = useRootStore().rootStore

  const [email, setEmail] = useState(initialEmail || '')
  const [invalidEmail, setInvalidEmail] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [busy, setBusy] = useState(false)

  const onChangeEmail = (e: any) => {
    setErrorMsg('')
    setInvalidEmail(false)
    setEmail(e.target.value)
  }

  const handleError = (error: { code: string; message: string }) => {
    setErrorMsg(error.message)
    setInvalidEmail(true)
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    if (busy) return
    setErrorMsg('')

    if (!email) {
      setInvalidEmail(true)
      return
    }

    setBusy(true)

    Api.resetPassword(email)
      .then(() => {
        toast.success(`Password reset instructions sent to ${email}`)
        setBusy(false)
        uiStore.closeSecondaryModal()
      })
      .catch((error) => {
        handleError(error)
        setBusy(false)
      })
  }

  return (
    <section>
      <ModalTitle text={text.resetPassword} />

      <form onSubmit={onSubmit}>
        <label htmlFor="password-reset-email">{text.yourEmail}</label>
        <div
          className={`input-container ${invalidEmail ? 'input_invalid' : ''}`}
        >
          <input
            type="email"
            id="password-reset-email"
            value={email}
            onChange={onChangeEmail}
            disabled={busy}
          />
        </div>

        {errorMsg ? <p className="form-error-msg">{errorMsg}</p> : null}

        <div className="modal-buttons__stack">
          <ButtonGeneric
            htmlButtonType="submit"
            text={text.resetPassword}
            onPress={onSubmit}
            disabled={busy}
          />
        </div>
      </form>
    </section>
  )
})

export default ResetPassword
