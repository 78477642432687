import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from '../../../store/RootStateProvider'
import { text } from '../../../util/text'
import ButtonGeneric from '../../Buttons/ButtonGeneric/ButtonGeneric'
import PasswordInput from '../../Forms/PasswordInput/PasswordInput'
import { Api } from '../../../services/api'
import ModalTitle from '../../ModalTitle/ModalTitle'

const ProfilePassword: React.FC<{}> = observer(() => {
  const { sessionStore, uiStore } = useRootStore().rootStore
  const user = sessionStore.currentUser

  const [oldPassword, setOldPassword] = useState('')
  const [invalidOldPassword, setInvalidOldPassword] = useState(false)

  const [newPassword, setNewPassword] = useState('')
  const [invalidNewPassword, setInvalidNewPassword] = useState(false)

  const [newPassword2, setNewPassword2] = useState('')
  const [invalidNewPassword2, setInvalidNewPassword2] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [busy, setBusy] = useState(false)

  const onChangePassword = (e: any, id: string) => {
    setErrorMsg('')

    switch (id) {
      case 'old':
        setInvalidOldPassword(false)
        setOldPassword(e.target.value)
        break

      case 'new':
        setInvalidNewPassword(false)
        setInvalidNewPassword2(false)
        setNewPassword(e.target.value)
        break

      case 'new2':
        setInvalidNewPassword(false)
        setInvalidNewPassword2(false)
        setNewPassword2(e.target.value)
        break

      default:
        break
    }
  }

  const handleError = (error: { code: string; message: string }) => {
    setErrorMsg(error.message)

    switch (error.code) {
      case 'auth/wrong-password':
        setInvalidOldPassword(true)
        break

      case 'auth/weak-password':
        setInvalidNewPassword(true)
        setInvalidNewPassword2(true)
        break

      default:
        break
    }
  }

  const resetErrors = () => {
    setInvalidOldPassword(false)
    setInvalidNewPassword(false)
    setInvalidNewPassword2(false)
    setErrorMsg('')
  }

  const passwordsValid = () => {
    if (!oldPassword) {
      setInvalidOldPassword(true)
      return false
    }

    if (!newPassword) {
      setInvalidNewPassword(true)
      return false
    }

    if (!newPassword2) {
      setInvalidNewPassword2(true)
      return false
    }

    if (newPassword !== newPassword2) {
      setInvalidNewPassword(true)
      setInvalidNewPassword2(true)

      setErrorMsg(text.passwordsDontMatch)

      return false
    }

    return true
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    resetErrors()

    if (busy || !passwordsValid()) {
      return
    }

    setBusy(true)

    Api.reAuthenticate(user.email, oldPassword)
      .then(() => {
        Api.updateUserPassword(newPassword)
          .then(() => {
            setBusy(false)
            uiStore.closeSecondaryModal()
          })
          .catch((error) => {
            handleError(error)
            setBusy(false)
          })
      })
      .catch((error) => {
        handleError(error)
        setBusy(false)
      })
  }

  return (
    <section>
      <ModalTitle text={text.changePassword} />

      <form onSubmit={onSubmit}>
        <label htmlFor="profile-password-old">{text.oldPassword}</label>
        <PasswordInput
          id="profile-password-old"
          password={oldPassword}
          onChange={(e: any) => onChangePassword(e, 'old')}
          invalid={invalidOldPassword}
          disabled={busy}
        />

        <label htmlFor="profile-password-new">{text.newPassword}</label>
        <PasswordInput
          id="profile-password-new"
          password={newPassword}
          onChange={(e: any) => onChangePassword(e, 'new')}
          invalid={invalidNewPassword}
          disabled={busy}
        />

        <label htmlFor="profile-password-new2">{text.confirmNewPassword}</label>
        <PasswordInput
          id="profile-password-new2"
          password={newPassword2}
          onChange={(e: any) => onChangePassword(e, 'new2')}
          invalid={invalidNewPassword2}
          disabled={busy}
        />

        {errorMsg ? <p className="form-error-msg">{errorMsg}</p> : null}

        <div className="modal-buttons__stack">
          <ButtonGeneric
            htmlButtonType="submit"
            text={text.changePassword}
            onPress={onSubmit}
            disabled={busy}
          />
        </div>
      </form>
    </section>
  )
})

export default ProfilePassword
